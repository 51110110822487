import { nanoid } from "nanoid";

const FITB_PATTERN = /__+/gm;
const LINK_REGEX = /<link .*?><\/link>/gm;

const TEXT_STYLE_ATTRIBUTES = [
  "-webkit-text-stroke",
  "color",
  "font-family",
  "font-size",
  "font-style",
  "font-weight",
  "letter-spacing",
  "line-height",
  "text-align",
  "text-decoration",
  "text-stroke",
];

const TEXT_STYLE_INHERIT = Object.fromEntries(
  TEXT_STYLE_ATTRIBUTES.map((attr) => [attr, "inherit"])
);

const zip = <T, U>(a: ArrayLike<T>, b: ArrayLike<U>): [T, U][] => {
  return Array.from(a).map((value, index) => [value, b[index]]);
};

const skippableDescentGenerator = function* (
  element,
  dom,
  options = { descend: true }
) {
  const toSearch = [[element, dom]];
  while (toSearch.length > 0) {
    const [element, dom] = toSearch.pop();

    if (dom.children.length !== 1 && !excuseMultipleChildren(dom)) {
      console.warn("Element's dom has multiple children", dom);
      continue;
    }
    if (
      element.children &&
      dom.children[0].children.length !== element.children.length
    ) {
      console.warn(
        "Mismatch in elelement and dom children length",
        element,
        dom
      );
      continue;
    }

    yield { element, dom };

    if (options.descend) {
      for (const [child, domChild] of zip(
        element.children || [],
        dom.children[0].children
      )) {
        toSearch.push([child, domChild]);
      }
    } else {
      options.descend = true;
    }
  }
};

const polotnoWithDomFind = (element, dom, pred) => {
  for (const pair of skippableDescentGenerator(element, dom)) {
    const { element, dom } = pair;
    if (pred({ element, dom })) {
      return { element, dom };
    }
  }
};

const polotnoWithDomFindAll = (element, dom, pred, noRecurseOnTrue = false) => {
  const results = [];
  const options = { descend: true };
  for (const pair of skippableDescentGenerator(element, dom, options)) {
    const { element, dom } = pair;
    options.descend = true;
    if (pred({ element, dom })) {
      results.push({ element, dom });
      if (noRecurseOnTrue) {
        options.descend = false;
      }
    }
  }
  return results;
};

const polotnoGetInnerText = (element) => {
  const toSearch = [element];
  let text = "";
  while (toSearch.length > 0) {
    const current = toSearch.pop();
    if (current.type === "text") {
      if (text) {
        text += " ";
      }
      text += current.text;
    }
    if (current.children) {
      for (const child of current.children) {
        toSearch.push(child);
      }
    }
  }
  return text;
};

const polotnoDomGetInnerText = (dom: PolotnoDom): string => {
  const toSearch = [dom];
  let text = "";
  while (toSearch.length > 0) {
    const current = toSearch.pop();
    if (typeof current === "string") {
      text += current;
    } else if (current?.children) {
      toSearch.push(...current.children);
    }
  }
  return text;
}

const excuseMultipleChildren = (dom) => dom.children[1].type === "input";

const mcqHandler = ({ element, dom, id, options, answerTypes }) => {
  const inputId = `${id}-val-${nanoid(5)}`;
  let inputParent = dom;
  let inputClass = "hidden";

  if (element.type === "text") {
    dom.type = "label";
    dom.props.for = inputId;
    dom.props.class = "box-checked";
  } else if (element.type === "group") {
    const markPair = polotnoWithDomFind(
      element,
      dom,
      ({ element }) => element.type === "figure"
    );

    const nonMarkPairs = polotnoWithDomFindAll(
      element,
      dom,
      ({ element }) =>
        element.type !== "group" && element.id !== markPair?.element?.id
    );

    if (markPair) {
      if (nonMarkPairs.length > 0) {
        inputParent = markPair.dom;
        inputClass = "overlay-check";
      } else {
        nonMarkPairs.push(markPair);
      }
    }

    for (const { dom } of nonMarkPairs) {
      dom.type = "label";
      dom.props.for = inputId;
      if (!markPair) {
        dom.props.class = "box-checked";
      }
    }
  } else {
    console.warn("Unknown element type for answer_mcq", element.type, element);
    return;
  }

  const text = polotnoGetInnerText(element);

  const input = {
    type: "input",
    props: {
      id: inputId,
      class: inputClass,
      type: "",
      name: id,
      value: text,
    },
    children: [],
  };

  if (options?.multiple) {
    answerTypes.add("mcq_multiple");
    input.props.type = "checkbox";
    inputParent.children.push(input);
  } else {
    answerTypes.add("mcq_single");
    input.props.type = "radio";
    inputParent.children.push(input);
  }
};

const prepReplaceBlockDom = ({ element, dom }) => {
  let mutateDom;
  if (element.type === "text") {
    mutateDom = dom;
  } else if (element.type === "group") {
    if (dom.children.length !== 1) {
      console.warn("replace block group has multiple direct children", dom);
      return;
    }
    if (dom.children[0].children.length === 0) {
      console.warn("replace block group has no children", dom);
      return;
    }
    mutateDom = dom.children[0].children[0];
    const bboxes = dom.children[0].children
      .map(
        ({
          props: {
            style: { left, top, width, height },
          },
        }) => [left, top, width, height]
      )
      .map((vals) =>
        vals.map((val) =>
          Number(typeof val === "string" ? val.replace("px", "") : val)
        )
      )
      .map(([left, top, width, height]) => ({
        x0: left,
        y0: top,
        x1: left + width,
        y1: top + height,
      }));

    const bbox = {
      x0: Math.min(...bboxes.map(({ x0 }) => x0)),
      y0: Math.min(...bboxes.map(({ y0 }) => y0)),
      x1: Math.max(...bboxes.map(({ x1 }) => x1)),
      y1: Math.max(...bboxes.map(({ y1 }) => y1)),
    };

    const position = {
      left: `${bbox.x0}px`,
      top: `${bbox.y0}px`,
      width: `${bbox.x1 - bbox.x0}px`,
      height: `${bbox.y1 - bbox.y0}px`,
    };

    Object.assign(mutateDom.props.style, position);

    dom.children[0].children.splice(1);
  } else {
    console.warn(
      "replace block is not a text or group, is type",
      element.type,
      element
    );
    return;
  }

  Object.assign(mutateDom.props.style, TEXT_STYLE_INHERIT);
  mutateDom.children = [];

  return mutateDom;
};

const textHandler = ({
  textLong,
  element,
  dom,
  id,
  answerTypes,
  textAnswers,
}: {
  textLong: boolean;
  element: PolotnoElement;
  dom: PolotnoDom;
  id: string;
  answerTypes: Set<string>;
  textAnswers: [PolotnoElement, PolotnoDom][];
}) => {
  const mutateDom = prepReplaceBlockDom({ element, dom });
  if (!mutateDom) {
    return;
  }
  if (textLong) {
    answerTypes.add("long");

    Object.assign(mutateDom, {
      type: "textarea",
      props: {
        ...mutateDom.props,
        name: id,
      },
      children: [],
    });
  } else {
    answerTypes.add("short");

    Object.assign(mutateDom, {
      type: "input",
      props: {
        ...mutateDom.props,
        type: "text",
        name: id,
        size: "1",
      },
      children: [],
    });
  }

  textAnswers.push([element, dom]);
};

const textHandlerInline = ({ textLong, dom, id, answerTypes }: {
  textLong: boolean;
  dom: PolotnoDom;
  id: string;
  answerTypes: Set<AnswerType>;
}) => {
  const domClone = structuredClone(dom);

  if (textLong) {
    answerTypes.add("long");

    Object.assign(dom, 
      createInlineReplace(domClone, 
        {
        type: "textarea",
        props: {
        name: id,
        },
        children: [],
      }),
    );
  } else {
    answerTypes.add("short");

    Object.assign(dom, 
      createInlineReplace(domClone, 
        {
        type: "input",
        props: {
          type: "text",
          name: id,
          size: "1",
        },
        children: [],
      }),
    );
  }
}

const satisfiesWeirdTextDomChildrenPattern = (dom) =>
  dom.children &&
  dom.children.length === 1 &&
  dom.children[0].children.length === 2 &&
  dom.children[0].children[0].children.length === 0 &&
  dom.children[0].children[1].children.length === 1 &&
  typeof dom.children[0].children[1].children[0] === "string";

const createInlineReplace = (replaced: PolotnoDom, replacement: PolotnoDom) => {
  return {
    type: "span",
    props: {
      class: "replace",
    },
    children: [
      replacement,
      {
        type: "span",
        props: {
          class: "replaced",
        },
        children: [replaced],
      },
    ],
  } satisfies PolotnoDom;
}

const fitbAfterHandler = ({ questionTextPairs, fitbAnswers, id }: {
  questionTextPairs: [PolotnoElement, PolotnoDom][];
  fitbAnswers: string[];
  id: string;
}) => {
  const match = questionTextPairs.find(([element]) => {
    return FITB_PATTERN.test(element.text);
  });
  if (!match) {
    console.warn("No fitb match found", questionTextPairs);
    return;
  }
  const [element, dom] = match;
  if (!satisfiesWeirdTextDomChildrenPattern(dom)) {
    console.warn("Invalid fitb question_text", dom, element);
    return;
  }

  const fitbMatch = FITB_PATTERN.exec(element.text)?.[0]!;
  const [before, after] = element.text.split(FITB_PATTERN);
  const middle = createInlineReplace(
    {
      type: "select",
      props: {
        name: id,
      },
      children: [
        {
          type: "option",
          props: {
            hidden: "hidden",
            disabled: "disabled",
            selected: "selected",
            value: "",
          },
          children: [],
        },
        ...fitbAnswers.map((text) => ({
          type: "option",
          props: {},
          children: [text],
        })),
      ],
    },
    fitbMatch,
  );

  dom.children[0].children[1].children = [before, middle, after];
};

const textAfterHandler = ({ questionTextPairs, textAnswers }) => {
  const [_questionTextElement, questionTextDom] = questionTextPairs[0];
  if (!satisfiesWeirdTextDomChildrenPattern(questionTextDom)) {
    console.warn("Invalid question text dom", questionTextDom);
    return;
  }
  const {
    props: { style },
  } = questionTextDom.children[0].children[1];
  const textStyle = TEXT_STYLE_ATTRIBUTES.reduce((acc, attr) => {
    acc[attr] = style[attr];
    return acc;
  }, {});
  for (const [_element, dom] of textAnswers) {
    Object.assign(dom.props.style, textStyle);
  }
};

type Falsy = false | 0 | "" | null | undefined;

type PolotnoDom = 
  | {
    type: string;
    props: Record<string, string>;
    children?: PolotnoDom[];
  }
  | string;
  // | Falsy;

const jsDomToPolotnoDom = (jsDom: Node): PolotnoDom => {
  if (jsDom.nodeType === Node.TEXT_NODE) {
    return jsDom.textContent || "";
  }

  if (jsDom.nodeType === Node.ELEMENT_NODE) {
    const element = jsDom as Element;
    const { innerHTML, ...props } = Object.fromEntries(
      Array.from(element.attributes).map((attr) => [attr.name, attr.value])
    );
    const children = innerHTML
      ? htmlToPolotnoDomChildren(innerHTML)
      : Array.from(element.childNodes).map(jsDomToPolotnoDom);
    return {
      type: element.tagName.toLowerCase(),
      props,
      children,
    };
  }

  return "";
}

const htmlToPolotnoDomChildren = (html: string): PolotnoDom[] => {
  const jsDom = new DOMParser().parseFromString(`<div>${html}</div>`, "text/html");
  const root = jsDom.body.firstChild!;
  const polotnoDom = jsDomToPolotnoDom(root);
  if (typeof polotnoDom !== "string" && polotnoDom) {
    return polotnoDom.children || [];
  }
  return [];
}

type PolotnoElement = {
  type: "text";
  text: string;
  custom?: {
    type?: string;
    options?: Record<string, string>;
  };
  name?: string;
  id?: string;
}

type ConvertedTextDom = PolotnoDom & {
  children: [
    {
      type: "div",
      props: {
        styles: Record<string, string>;
      },
      children: [
        {
          type: "div",
          props: {
            styles: Record<string, string>;
          },
          children: [],
        },
        {
          type: "div",
          props: {
            styles: Record<string, string>;
            innerHTML?: string;
          },
          children: PolotnoDom[],
        }
      ]
    }
  ]
}

const isConvertedTextDom = (element: PolotnoElement, dom: PolotnoDom): dom is ConvertedTextDom => {
  return element.type === "text";
}

const polotnoDomHasClass = (className: string, dom: PolotnoDom) => {
  return typeof dom !== "string" && dom.props?.class?.split(" ")?.includes(className);
}

function* polotnoDomGetByClass(className: string, ...doms: PolotnoDom[]) {
  const toSearch = [...doms];

  while (toSearch.length > 0) {
    const current = toSearch.pop();
    if (typeof current === "string" || !current) {
      continue;
    }
    if (polotnoDomHasClass(className, current)) {
      yield current;
    } else {
      toSearch.push(...[...(current.children || [])].reverse());
    }
  }
}

type AnswerType = "mcq_single" | "mcq_multiple" | "fitb" | "short" | "long";

type IdMap = Record<string, {
  name: string;
  answerTypes: AnswerType[];
  questionText: string;
  correctAnswer?: string;
  options?: string[]
}>;

type DataType = "mcq" | "fitb" | "short" | "long";

const answerTypeMap = {
  "mcq": "mcq_single",
  "fitb": "fitb",
  "short": "short",
  "long": "long",
} as const satisfies Record<DataType, AnswerType>;

const processConvertedTextDom = (element: PolotnoElement, rootDom: ConvertedTextDom, idMap: IdMap) => {
  for (const container of polotnoDomGetByClass("questioncontainer", rootDom)) {
    const randomId = nanoid(5);

    const mcqOptions: string[] = [];

    const questionType = container.children?.flatMap((child) => typeof child !== "string" && child.props?.["data-type"] || [])[0] as DataType | undefined;
    if (!questionType) {
      console.warn("No question type found", container);
      continue;
    }

    const correctAnswer = container.children?.flatMap((child) => typeof child !== "string" && child.props?.["data-correctanswer"] || [])[0];

    for (const dom of polotnoDomGetByClass("questioncontainer-para", container)) {
      delete dom.props["data-correctanswer"];
    }

    const answerTypes = new Set<AnswerType>([answerTypeMap[questionType]]);
    let questionText = "";

    const options = element.custom?.options;

    for (const dom of polotnoDomGetByClass("question-true", container)) {
      questionText += polotnoDomGetInnerText(dom);
    }

    let mcqIndex = 1;

    const id = element.name === "student-name" ? "student-name" : randomId;

    for (const dom of polotnoDomGetByClass("answer-true", container)) {
        if (questionType === "mcq") {
          const element = {
            type: "text",
            text: mcqIndex.toString(),
          };
          mcqHandler({ element, dom, id, options, answerTypes });
          mcqOptions.push(polotnoDomGetInnerText(dom));
          mcqIndex++;
        } else if (questionType === "fitb") {
          // TODO: Implement FITB
          // answerTypes.add("fitb");
          // fitbAnswers.push(text);
        } else if (questionType === "short") {
          textHandlerInline({
            textLong: false,
            dom,
            id,
            answerTypes,
          });
        } else if (questionType === "long") {
          textHandlerInline({
            textLong: true,
            dom,
            id,
            answerTypes,
          });
        }
      }

      const name = element.name || "[unnamed question]";
      idMap[id] = {
        name,
        answerTypes: Array.from(answerTypes),
        questionText,
        correctAnswer,
        options: mcqOptions,
      };
  }
}


const elementHookCreator =
  (idMap: IdMap, extraData: ExtraData) =>
  ({ element, dom }: { element: PolotnoElement, dom: PolotnoDom }) => {
    if (isConvertedTextDom(element, dom) && /<[a-z][\s\S]*>/i.test(element.text) && dom?.children?.[0]?.children?.[1]?.props?.innerHTML) {
      dom.children[0].children[1].children = htmlToPolotnoDomChildren(dom.children[0].children[1].props.innerHTML);
      delete dom.children[0].children[1].props.innerHTML;
      processConvertedTextDom(element, dom, idMap);
    }

    if (element.type === "text" && element.custom?.type === "title") {
      extraData.title += element.text;
    }

    if (element.custom?.type === "question_container") {
      const { id } = element;
      dom.props.id = id;

      const answerTypes = new Set();
      let questionText = "";

      const options = element.custom?.options;

      const questionTextPairs = [];
      const fitbAnswers = [];
      const textAnswers = [];

      const descentOptions = { descend: true };

      for (const pair of skippableDescentGenerator(
        element,
        dom,
        descentOptions
      )) {
        const { element, dom } = pair;

        descentOptions.descend = false;

        const type = element.custom?.type;

        if (type === "question_text") {
          questionText += element.text;
          questionTextPairs.push([element, dom]);
        } else if (type === "answer_mcq") {
          mcqHandler({ element, dom, id, options, answerTypes });
        } else if (type === "answer_fitb") {
          answerTypes.add("fitb");
          const text = polotnoGetInnerText(element);
          fitbAnswers.push(text);
        } else if (type === "answer_short") {
          textHandler({
            textLong: false,
            element,
            dom,
            id,
            answerTypes,
            textAnswers,
          });
        } else if (type === "answer_long") {
          textHandler({
            textLong: true,
            element,
            dom,
            id,
            answerTypes,
            textAnswers,
          });
        } else {
          descentOptions.descend = true;
        }
      }

      if (answerTypes.has("fitb")) {
        fitbAfterHandler({ questionTextPairs, fitbAnswers, id });
      }

      if (textAnswers.length > 0 && questionTextPairs.length > 0) {
        textAfterHandler({ questionTextPairs, textAnswers });
      }

      const name = element.name || "[unnamed question]";
      idMap[id] = {
        name,
        answerTypes: Array.from(answerTypes),
        questionText,
      };
      return dom;
    }
  };

const htmlOutputProcessorCreator = (idMap: IdMap, extraData: ExtraData) => (html: string) => {
  const links = Array.from(html.matchAll(LINK_REGEX)).map(([link]) => link);
  const insertableHTML = html.replace(LINK_REGEX, "");
  return `
<html>
<head>
${links.join("\n")}
<title>${extraData.title ?? "TeachShare Resource"}</title>
<style>
.hidden {
  display: none;
}

label.box-checked:has(input[type="radio"]:checked),
label.box-checked:has(input[type="checkbox"]:checked) {
  outline: black solid 1px;
}

.overlay-check:checked::before {
  width: 50%;
  height: 50%;
  content: "";
  background-color: white;
  top: 25%;
  left: 25%;
  position: absolute;
}

.overlay-check[type="radio"]:checked::before {
  border-radius: 100%;
}

.overlay-check {
  position: absolute;
  inset: 0;
  padding: 0;
  margin: 0;
  appearance: none;
}

span.replace {
  position: relative;
  display: inline-flex;
  vertical-align: baseline;
  height: fit-content;
  flex-direction: column;
  max-width: 100%;
}

span.replace .replaced {
  display: inline-block;
  visibility: hidden;
  height: 0;
}

div[classname="design"] > [classname="page"]+[classname="page"] {
  border-top: none !important;
}

div[classname="design"] > [classname="page"] {
  border-bottom: none !important;
}

input[type="text"] {
  height: 1em;
}

input[type="text"], textarea {
  display: flex;
  border-radius: 0.5rem;
  border: 1px solid rgb(228, 228, 231);
  background-color: transparent;
  padding: 0.5rem 0.75rem;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input[type="text"]::placeholder, textarea::placeholder {
  color: hsl(240 5% 64.9%);
}

input[type="text"]:focus-visible, textarea:focus-visible {
  outline: none;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 2px, rgb(24, 24, 27) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  z-index: 1;
}

input[type="text"]:disabled, textarea:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

form[classname="design"] {
  zoom: 1.5;
}

body, [classname="design"] {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="submit"] {
  cursor: pointer;
  border-radius: 0.5rem;
  border: 1px solid rgb(228, 228, 231);
  background-color: transparent;
  padding: 0.5rem 0.75rem;
  color: inherit;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: inherit;
  line-height: inherit;
}

.submit-container {
  border: 1px solid grey;
  border-top: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding: 0.5rem;
}
</style>
</head>
<body>
<form id="form" classname="design">
${insertableHTML}
<div class="submit-container">
<input type="submit" value="Submit">
</div>
</form>
<script>
const idMap = ${JSON.stringify(idMap)};
document.getElementById("form").addEventListener("submit", (e) => {
  e.preventDefault();
  alert(Array.from((new FormData(e.target)).entries()).map(([key, val]) => \`\${idMap?.[key]?.questionText || "[unnamed question]"}: \${val}\`).join("\\n"))
});
</script>
</body>
</html>
  `.trim();
};

type ExtraData = {
  title: string,
}

export const openInteractiveExport = (store) => {
  const idMap = {};
  const extraData: ExtraData = {
    title: "",
  };

  return store
    .toHTML({
      elementHook: elementHookCreator(idMap, extraData),
    })
    .then(async (html: string) => {
      const data = {
        idMap,
        extraData,
        insertableHTML: html,
      };
      console.log({data});
      const res = await fetch("/api/form/form_creator", {
        method: "POST",
        body: JSON.stringify(data),
      });
      const { id } = await res.json() as { id: string };
      const url = `/editor-form/${id}`;
      window.open(url, "_blank");
    })
    // .then(htmlOutputProcessorCreator(idMap, extraData))
    // .then((html: string) =>
    //   URL.createObjectURL(new Blob([html], { type: "text/html" }))
    // )
    // .then((url) => {
    //   window.open(url, "_blank");
    // });
};
