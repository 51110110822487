"use client";

import React from "react";
import * as mobx from "mobx";
import { observer } from "mobx-react-lite";
import {
  Button,
  Dialog,
  Classes,
  Position,
  Menu,
  MenuItem,
  MenuDivider,
  Popover,
  Spinner,
  FormGroup,
  InputGroup,
  Divider,
  HTMLTable,
  Checkbox,
} from "@blueprintjs/core";
import { downloadFile } from "polotno/utils/download";
import { getTpt, postTpt } from "../api";
import { augmentResources, hasExtension } from "../extension";
import type { StoreType } from "polotno/model/store";
import type { useProject } from "../project";

export const FileMenu = observer((
  {
    store,
    project,
    pdfInputRef,
  }: {
    store: StoreType;
    project: ReturnType<typeof useProject>;
    pdfInputRef: React.RefObject<HTMLInputElement>;
  }
) => {
  const inputRef = React.useRef();

  const [faqOpened, toggleFaq] = React.useState(false);

  const [tptOpened, setTptOpened] = React.useState(false);

  const [resourcesLoading, setResourcesLoading] = React.useState(false);

  const [resources, setResources] = React.useState<Awaited<
    ReturnType<typeof getTpt>
  > | null>(null);

  const [extension, setExtension] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(async () => {
      if (tptOpened) {
        setExtension(await hasExtension());
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [setExtension, tptOpened]);

  const [postResourcesLoading, setPostResourcesLoading] = React.useState(false);

  return (
    <>
      {/* <Popover
        content={ */}
          {/* <MenuItem text="File"> */}
            {/* <MenuDivider title={t('toolbar.layering')} /> */}
            <MenuItem
              icon="plus"
              text="Create new design"
              onClick={() => {
                project.createNewDesign();
              }}
             />
            <MenuItem
              icon="lightning"
              text={project.basicMode ? "Switch to advanced mode" : "Switch to standard mode"}
              onClick={() => {
                project.setBasicMode(!project.basicMode);
              }}
            />
            {/* <MenuDivider /> */}
            {/* <MenuItem
              // icon={<FaFileImport />}
              icon="folder-open"
              text="Open"
              onClick={() => {
                document.querySelector('#load-project').click();
              }}
            />
            <MenuDivider /> */}
            {/* <MenuItem
              // icon={<FaFileExport />}
              icon="floppy-disk"
              text="Save as JSON"
              onClick={() => {
                const json = store.toJSON();

                const url =
                  'data:text/json;base64,' +
                  window.btoa(
                    unescape(encodeURIComponent(JSON.stringify(json)))
                  );

                downloadFile(url, 'polotno.json');
              }}
            /> */}

            <MenuDivider />
            <MenuItem text="Language" icon="translate">
              <MenuItem
                text="English"
                active={project.language.startsWith("en")}
                onClick={() => {
                  project.setLanguage("en");
                }}
              />
              <MenuItem
                text="Portuguese"
                active={project.language.startsWith("pt")}
                onClick={() => {
                  project.setLanguage("pt");
                }}
              />
              <MenuItem
                text="French"
                active={project.language.startsWith("fr")}
                onClick={() => {
                  project.setLanguage("fr");
                }}
              />
              <MenuItem
                text="Russian"
                active={project.language.startsWith("ru")}
                onClick={() => {
                  project.setLanguage("ru");
                }}
              />
              <MenuItem
                text="Indonesian"
                active={project.language.startsWith("id")}
                onClick={() => {
                  project.setLanguage("id");
                }}
              />
            </MenuItem>
            {/* <MenuItem
              text="About"
              icon="info-sign"
              onClick={() => {
                toggleFaq(true);
              }}
            /> */}
          {/* </MenuItem> */}
      {!project.basicMode && (
        <>
          <MenuDivider />
        {/* }
        position={Position.BOTTOM_RIGHT}
      > */}
        {/* <Button minimal text="File" /> */}
      {/* </Popover> */}
      <input
        type="file"
        id="load-project"
        accept=".json,.polotno"
        ref={inputRef}
        style={{ width: "180px", display: "none" }}
        onChange={(e) => {
          var input = e.target;

          if (!input.files.length) {
            return;
          }

          var reader = new FileReader();
          reader.onloadend = async function () {
            var text = reader.result;
            console.log(JSON.parse(text));
            let json;
            try {
              json = JSON.parse(text);
            } catch (e) {
              alert("Can not load the project.");
            }

            if (json) {
              await project.createNewDesign();
              store.loadJSON(json);
              project.save();
              input.value = "";
            }
          };
          reader.onerror = function () {
            alert("Can not load the project.");
          };
          reader.readAsText(input.files[0]);
        }}
      />
      <Dialog
        icon="info-sign"
        onClose={() => toggleFaq(false)}
        title="About Polotno Studio"
        isOpen={faqOpened}
        style={{
          width: "80%",
          maxWidth: "700px",
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <h2>What is Polotno Studio?</h2>
          <p>
            <strong>Polotno Studio</strong> - is a web application to create
            graphical designs. You can mix image, text and illustrations to make
            social media posts, youtube previews, podcast covers, business cards
            and presentations.
          </p>
          <h2>Is it Open Source?</h2>
          <p>
            Partially. The source code is available in{" "}
            <a href="https://github.com/lavrton/polotno-studio" target="_blank">
              GitHub repository
            </a>
            . The repository doesn&apos;t have full source.{" "}
            <strong>Polotno Studio</strong> is powered by{" "}
            <a href="https://polotno.com/" target="_blank">
              Polotno SDK project
            </a>
            . All core &qout;canvas editor&qout; functionality are implemented
            by <strong>polotno</strong> npm package (which is not open source at
            the time of writing this text).
          </p>
          <p>
            Polotno Studio is build on top of Polotno SDK to provide a
            desktop-app-like experience.
          </p>
          <h2>Who is making Polotno Studio?</h2>
          <p>
            My name is Anton Lavrenov{" "}
            <a href="https://twitter.com/lavrton" target="_blank">
              @lavrton
            </a>
            . I am founder of Polotno project. As the maintainer of{" "}
            <a href="https://konvajs.org/" target="_blank">
              Konva 2d canvas framework
            </a>
            , I created several similar apps for different companies around the
            world. So I decided to compile all my knowledge and experience into
            reusable Polotno project.
          </p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          </div>
        </div>
      </Dialog>
      <Dialog
        icon="import"
        onClose={() => setTptOpened(false)}
        title="Import from Store (experimental)"
        isOpen={tptOpened}
        style={{
          width: "80%",
          maxWidth: "700px",
          maxHeight: "100vh",
          overflow: "auto",
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          {extension ? (
            <>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  const form = e.target as HTMLFormElement;
                  const formData = new FormData(form);
                  const slug = formData.get("slug") as string;

                  setResourcesLoading(true);
                  try {
                    const resources = await getTpt({ slug });
                    console.log({ resources });
                    setResources(resources);
                  } finally {
                    setResourcesLoading(false);
                  }
                }}
              >
                <FormGroup
                  helperText="For example: teacherspayteachers.com/store/awesome-store → awesome_store"
                  label="Store Slug"
                  labelFor="tpt-slug-input"
                >
                  <InputGroup
                    id="tpt-slug-input"
                    name="slug"
                    required
                    placeholder="my-store"
                  />
                </FormGroup>
                <Button
                  type="submit"
                  intent="primary"
                  loading={resourcesLoading}
                >
                  Submit
                </Button>
              </form>
              <p className="mt-4">
                Make sure you are logged into{" "}
                <a href="https://teacherspayteachers.com">
                  teacherspayteachers.com
                </a>{" "}
                with the account you entered above!
              </p>
              {resources && (
                <>
                  <Divider />
                  <form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      setPostResourcesLoading(true);
                      try {
                        const form = e.target as HTMLFormElement;
                        const formData = new FormData(form);
                        const selected = new Set(
                          formData.getAll("import") as string[]
                        );
                        const selectedResources = resources.filter(
                          ({ canonicalSlugs }) =>
                            selected.has(canonicalSlugs[0])
                        );
                        const augmentedResources = (await augmentResources(
                          selectedResources
                        )) as Parameters<typeof postTpt>[0]["resources"];
                        await postTpt({
                          slug: "unused",
                          resources: augmentedResources,
                        });
                        mobx.runInAction(() => {
                          project.designsLength++;
                        });
                        setTptOpened(false);
                      } finally {
                        setPostResourcesLoading(false);
                      }
                    }}
                  >
                    <HTMLTable>
                      <thead>
                        <tr>
                          <th>Import?</th>
                          <th>Title</th>
                          <th>Preview</th>
                        </tr>
                      </thead>
                      <tbody>
                        {resources.map(
                          ({ title, thumbnailUrl, canonicalSlugs }) => (
                            <tr key={canonicalSlugs[0]}>
                              <td>
                                <Checkbox
                                  name="import"
                                  value={canonicalSlugs[0]}
                                />
                              </td>
                              <td>{title}</td>
                              <td>
                                <img src={thumbnailUrl} alt="Preview" />
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </HTMLTable>
                    <Button
                      type="submit"
                      intent="primary"
                      loading={postResourcesLoading}
                    >
                      Import Selected
                    </Button>
                  </form>
                </>
              )}
            </>
          ) : (
            <p>
              Please install the{" "}
              <a
                href={`https://chromewebstore.google.com/detail/${import.meta.env.VITE_PUBLIC_EXTENSION_ID}`}
                target="_blank"
              >
                browser extension
              </a>{" "}
              to use this feature.
            </p>
          )}
        </div>
      </Dialog>
      <MenuItem
        // icon={<FaFileImport />}
        icon="import"
        text="Import PDF"
        onClick={(e) => {
          if (project.demoMode) {
            project.openDemoDialog();
            e.preventDefault();
            e.stopPropagation();
            return;
          }
          if (!project.cloudEnabled) {
            project.openLoginDialog();
            e.preventDefault();
            e.stopPropagation();
            return;
          }
          if (!pdfInputRef.current) return;
          pdfInputRef.current.click();
        }}
      />
      <MenuItem
        // icon={<FaFileImport />}
        icon="import"
        text="Import from Store"
        onClick={(e) => {
          if (project.demoMode) {
            project.openDemoDialog();
            e.preventDefault();
            e.stopPropagation();
            return;
          }
          if (!project.cloudEnabled) {
            project.openLoginDialog();
            e.preventDefault();
            e.stopPropagation();
            return;
          }
          setTptOpened(true);
        }}
      />
    </>
    )}
  </>
  );
});
