import * as Sentry from "@sentry/react";
import posthog from "posthog-js";

export const feedbackIntegration = Sentry.feedbackIntegration({
  autoInject: false,
  colorScheme: "light",
  tags: {
    "service": "creator",
  },
  showBranding: false,
});

Sentry.init({
  dsn: "https://47cab2ca9684cb6ed7522142a44542e2@o4506661752733696.ingest.us.sentry.io/4507530619715584",
  integrations: [
    Sentry.replayIntegration(),
    feedbackIntegration,
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY,
  {
    api_host: import.meta.env.VITE_PUBLIC_POSTHOG_API_HOST,
    ui_host: import.meta.env.VITE_PUBLIC_POSTHOG_UI_HOST,
    person_profiles: "always",
  }
);
