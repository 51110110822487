import { getCustomerSession } from "../api";
import { useProject } from "../project";
import { Button, Dialog, DialogBody, Icon } from "@blueprintjs/core";
import { skipToken, useQuery } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
        'pricing-table-id': string;
        'publishable-key': string;
        'customer-session-client-secret'?: string;
        'class'?: string;
      };
    }
  }
}

const pricingTableId = import.meta.env.VITE_PUBLIC_STRIPE_PRICING_TABLE_ID as string;
const publishableKey = import.meta.env.VITE_PUBLIC_STRIPE_PUBLISHABLE_KEY as string;

const Loading = () => <p>Loading...</p>;

const PricingTable = observer(() => {
  const project = useProject();

  useEffect(() => {
    if (!project.cloudEnabled) {
      project.openLoginDialog();
    }
  }, [project.cloudEnabled]);

  const customerSessionQuery = useQuery({
    queryKey: ["customerSession", project.cloudEnabled],
    queryFn: async () => project.cloudEnabled ? await getCustomerSession() : skipToken,
  });
  const customerSession = customerSessionQuery.data;
  if (customerSessionQuery.isLoading) return <Loading />;
  if (customerSessionQuery.isError || !customerSession) return <p>Sorry, something went wrong.</p>;
  if (customerSession === skipToken) return <p>Please log in to continue.</p>;

  const { client_secret } = customerSession.session;

  return (
    <stripe-pricing-table
      pricing-table-id={pricingTableId}
      publishable-key={publishableKey}
      // customer={stripeCustomerId()}
      // client-reference-id={stripeCustomerId()}
      // customer-session-client-secret={"_PWiJDcYrJCmhnPivh1yFCVLvyozO82PzfOhRq4D3qfM4Kw6"}
      customer-session-client-secret={client_secret}
      class="overflow -y-auto w-full"
    ></stripe-pricing-table>
  )
});

const PricingTableDialog = (props: { open: boolean, onClose: () => void, openReferralDialog: () => void }) => (
  <Dialog isOpen={props.open} onClose={() => props.onClose()} className="w-[unset] p-8 relative max-h-[90vh]">
    <Icon
      icon="cross"
      size={24}
      role="button"
      aria-label="Close"
      className="absolute w-6 h-6 top-4 right-4 cursor-pointer"
      onClick={() => props.onClose()}
    />
    <div className="flex max-h-full w-full max-w-[unset] flex-col sm:w-96 md:w-[48rem] gap-2 overflow-y-auto">
      <div className="flex flex-col space-y-1.5 text-center sm:text-left">
        <h2 id="dialog-cl-100-title" className="text-lg font-semibold leading-none tracking-tight">
          Plans
        </h2>
        <p id="dialog-cl-100-description" className="text-sm text-muted-foreground">
          Choose a plan to continue.
        </p>
      </div>
      <div className="self-center">
        <Button textClassName="text-xl" onClick={() => props.openReferralDialog()}>Refer a Friend for Free Access!</Button>
      </div>
      <div className="self-center text-lg">– OR –</div>
      <div className="flex flex-1 flex-col items-center gap-3 overflow-y-auto">
        <PricingTable />
      </div>
    </div>
    {/* min-[696px]:w-[44rem]; Four wide: min-[1329px]:w-[83.125rem] */}
    {/* <DialogContent class="flex max-h-full w-full max-w-[unset] flex-col sm:w-96 md:w-[48rem]">
      <DialogHeader>
        <DialogTitle>Plans</DialogTitle>
        <DialogDescription>Choose a plan to continue.</DialogDescription>
      </DialogHeader>
      <div class="flex flex-1 flex-col items-center gap-3 overflow-y-auto">
        <PricingTable />
        <span>– OR –</span>
        <Button
          onClick={preventDefaultAnd(() => {
            track("Refer a Friend from Plans, Button Clicked", {
              uid: user()?.uid,
            });
            setReferralDialogOpen(true);
            setPricingTableOpen(false);
          })}
        >
          Refer a Friend!
        </Button>
      </div>
    </DialogContent> */}
  </Dialog>
);

export const ProjectPricingTableDialog = observer(() => {
  const project = useProject();
  return <PricingTableDialog open={project.pricingTableOpen} onClose={() => project.setPricingTableOpen(false)} openReferralDialog={() => project.openReferralDialog()} />;
})

export const GiftPricingTableDialog = observer(() => {
  const [open, setOpen] = useState(false);

  return <>
    <Button small onClick={() => setOpen(true)}>Gift Subscription</Button>
    <Dialog isOpen={open} onClose={() => setOpen(false)} className="w-[unset] p-8 relative max-h-[90vh]">
      <Icon
        icon="cross"
      size={24}
      role="button"
      aria-label="Close"
      className="absolute w-6 h-6 top-4 right-4 cursor-pointer"
      onClick={() => setOpen(false)}
    />
    <div className="flex max-h-full w-full max-w-[unset] flex-col sm:w-96 md:w-[48rem] xl:w-[64rem] gap-2 overflow-y-auto">
      <div className="flex flex-col space-y-1.5 text-center sm:text-left">
        <h2 className="text-lg font-semibold leading-none tracking-tight">
          Gift Subscription
        </h2>
        <p className="text-sm text-muted-foreground">
          Make a teacher's month!
        </p>
      </div>
      <div className="flex flex-1 flex-col items-center gap-3 overflow-y-auto">
        <stripe-pricing-table
          pricing-table-id={"prctbl_1Q6KWrEcUW6WhXmB6Ijm8uyX"}
          publishable-key={publishableKey}
          // customer-session-client-secret={client_secret}
          class="overflow -y-auto w-full"
        ></stripe-pricing-table>
      </div>
    </div>
  </Dialog>
</>
})
