import ArrowSvg from "./icons/Arrow";
import ImportSvg from "./icons/Import";
import RefineSvg from "./icons/Ai";
import BookSvg from "./icons/Book";
import LinkSvg from "./icons/Link";
import VideoSvg from "./icons/Video";
import DocumentSvg from "./icons/Document";
import CloudSvg from "./icons/Cloud";
import BucketSvg from "./icons/Bucket";
import FlashSvg from "./icons/Flash";
import UploadSvg from "./icons/DocumentUpload";
import LocationSvg from "./icons/Location";
import MagicpenSvg from "./icons/Magicpen";
import SearchSvg from "./icons/Search";
import { Dispatch, SetStateAction, useContext, useMemo, useRef, useState } from "react";
import { Button, Dialog, Drawer, Icon } from "@blueprintjs/core";
import { useProject } from "../project";
import { RegenSegment } from "../sections/stable-diffusion-section";
import { observer } from "mobx-react-lite";
import { LabelledGroupedSelect, LabelledInput, LabelledSelect, LabelledSimpleSelect } from "./arg_form";
import { useSize } from "../utils";
import {
  getLearningStandardJurisdictions,
  getLearningStandardStandards,
  getLearningStandardStandardSets,
} from "../api";
import { skipToken, useQuery } from "@tanstack/react-query";
import Fuse from "fuse.js";
import { LearningStandardEntries, SelectEntries } from "../types";
import { StoreType } from "polotno/model/store";

function Standard({
  value,
  standard,
  description,
  selected,
  setList,
}: {
  value: string;
  standard?: string;
  description: string;
  selected: boolean;
  setList: Dispatch<SetStateAction<LearningStandardEntries>>;
}) {

  return (
    <div className="flex flex-col w-full text-left py-4 gap-2">
      <div className="flex flex-row gap-2">
        {standard && (
          <p className="text-white text-sm font-semibold py-1 px-4 bg-sky-500 rounded-full">
            {standard}
          </p>
        )}
        {selected ? (
          <button
            className="rounded-md bg-primary text-white font-semibold w-24 text-sm"
            onClick={() => {
              setList((list) => list.filter((item) => item.value !== value));
            }}
          >
            Selected
          </button>
        ) : (
          <button
            className="rounded-md border-2 border-primary text-primary font-semibold w-24 text-sm"
            onClick={() => {
              setList((list) => [
                ...list,
                { value, label: standard ?? `${description.slice(0, 17)}...`, description },
              ]);
            }}
          >
            Select
          </button>
        )}
      </div>
      <p className="text-gray-800 text-xs">{description}</p>
    </div>
  );
}

function Modal({
  show,
  onClose,
  selected,
  setSelected,
}: {
  show: boolean;
  onClose: (arg0: boolean) => void;
  selected: LearningStandardEntries;
  setSelected: Dispatch<SetStateAction<LearningStandardEntries>>;
}) {
  const [jurisdiction, setJurisdiction] = useState<string>("");
  const [standardSet, setStandardSet] = useState<string>("");

  const [search, setSearch] = useState("");

  const jurisdictionsQuery = useQuery({
    queryKey: ["jurisdictions", show],
    queryFn: async () =>
      show
        ? getLearningStandardJurisdictions()
            .then(
              ({
                state,
                country,
                nation,
                organization,
                corporation,
                school,
              }) => ({
                state,
                country,
                nation,
                organization,
                corporation,
                school,
              })
            )
            .then((data) =>
              Object.entries(data).map(([group, entries]) => ({
                group,
                label: group.slice(0, 1).toUpperCase() + group.slice(1),
                entries: entries.map(({ id, title }) => ({
                  value: id,
                  label: title,
                })),
              }))
            )
        : skipToken,
  });
  const standardSetsQuery = useQuery({
    queryKey: ["standardSets", show, jurisdiction],
    queryFn: async () =>
      show && jurisdiction
        ? getLearningStandardStandardSets({ jurisdictionId: jurisdiction })
        : skipToken,
  });
  const standardsQuery = useQuery({
    queryKey: ["standards", show, jurisdiction, standardSet],
    queryFn: async () =>
      show && jurisdiction && standardSet
        ? getLearningStandardStandards({ standardSetId: standardSet })
        : skipToken,
  });

  const fuse = useMemo(
    () =>
      standardsQuery.isSuccess &&
      standardsQuery.data !== skipToken &&
      new Fuse(standardsQuery.data, {
        keys: ["statementNotation", "description"],
      }),
    [standardsQuery]
  );

  const filteredStandards = useMemo(() => {
    if (!jurisdiction || !standardSet) {
      return [];
    }

    if (!standardsQuery.isSuccess || standardsQuery.data === skipToken) {
      return [];
    }

    if (!fuse || !search) {
      return standardsQuery.data;
    }

    return fuse.search(search).map((result) => result.item);
  }, [jurisdiction, standardSet, standardsQuery, search, fuse]);

  if (!show) {
    return <></>;
  }

  return (
    <Dialog
      isOpen={show}
      onClose={() => onClose(false)}
      className="w-[unset] p-8 relative bg-primary"
      enforceFocus={false}
    >
      <Icon
        icon="cross"
        size={24}
        role="button"
        aria-label="Close"
        className="absolute w-6 h-6 top-4 right-4 cursor-pointer text-white"
        onClick={() => onClose(false)}
      />
      <div className="flex max-h-full w-full max-w-[unset] flex-col sm:w-96 md:w-[48rem]">
        <div className="text-left my-4">
          <p className="font-semibold text-xl text-white" id="modal-title">
            Add new curriulum standard (beta)
          </p>
        </div>
        <div className="flex flex-row justify-between gap-6">
          <div className="flex-1">
            <LabelledGroupedSelect
              label="Source (state, organization, etc.)"
              id="jurisdiction-input"
              name="name"
              fallbackText="Select a standards source..."
              loading={jurisdictionsQuery.isLoading}
              groups={
                jurisdictionsQuery.isSuccess &&
                  jurisdictionsQuery.data !== skipToken
                  ? jurisdictionsQuery.data
                  : []
              }
              selectedValue={jurisdiction}
              onItemSelect={(item) => setJurisdiction(item.value)}
              icon={<LocationSvg width={20} height={20} />}
            />
          </div>
          {/* <div className="flex flex-col w-full text-left gap-2">
                <p className="text-white text-sm font-semibold">State</p>
                <button
                  type="button"
                  className="inline-flex justify-between rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900 text-center"
                  id="location-menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                >
                  <div className="flex flex-row gap-2">
                    <LocationSvg width={20} height={20} />
                    <span className="font-semibold">California</span>
                  </div>
                  <ArrowSvg width={20} height={20} />
                </button>
              </div> */}
          <div className="flex-1">
            <LabelledSelect
              label="Standard Set"
              id="standard-set-input"
              name="name"
              fallbackText="Select a standard set..."
              loading={standardSetsQuery.isLoading}
              entries={
                standardSetsQuery.isSuccess &&
                  standardSetsQuery.data !== skipToken
                  ? standardSetsQuery.data.map(({ id, title, subject }) => ({
                    value: id,
                    label: subject ? `${title} (${subject})` : title,
                  }))
                  : []
              }
              selectedValue={standardSet}
              onItemSelect={(item) => setStandardSet(item.value)}
            />
          </div>
          {/* <div className="flex flex-col w-full text-left gap-2">
                <p className="text-white text-sm font-semibold">Subject</p>
                <button
                  type="button"
                  className="inline-flex justify-between rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900 text-center"
                  id="cs-subject-menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                >
                  <div className="flex flex-row gap-2">
                    <MagicpenSvg width={20} height={20} />
                    <span className="font-semibold">ELA Grade 5</span>
                  </div>
                  <ArrowSvg width={20} height={20} />
                </button>
              </div> */}
        </div>
        <div className="flex flex-col w-full text-left gap-2">
          <p className="text-white text-sm font-semibold mb-0">Search</p>
          <div
            className="inline-flex justify-between rounded-lg bg-white px-3 h-10 py-2 text-sm font-semibold text-gray-900"
            id="cs-menu-menu-button"
            aria-expanded="true"
            aria-haspopup="true"
          >
            <div className="flex flex-row gap-2 overflow-hidden w-full">
              <SearchSvg width={18} height={18} />
              {selected.map((entry) => (
                <p
                  key={entry.value}
                  className="text-white text-sm font-semibold px-4 py-0.5 bg-sky-500 rounded-full text-center mb-0"
                >
                  {entry.label}
                </p>
              ))}
              <input
                placeholder="L.CCR"
                className="font-bold outline-none flex-1"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="overflow-y-scroll h-80 divide-y bg-white rounded-lg px-4 py-2">
            {standardsQuery.isLoading ? (
              <p className="text-gray-800 text-sm">Loading...</p>
            ) : filteredStandards.length === 0 ? (
              <p className="text-gray-800 text-sm">No matching standards found.</p>
            ) : filteredStandards.map((entry, index) => (
              <Standard
                key={entry.id}
                value={entry.id}
                standard={entry.statementNotation ?? undefined}
                description={entry.description}
                selected={selected.some((item) => item.value === entry.id)}
                setList={setSelected}
              />
            ))}
          </div>
        </div>
        <div className="px-4 py-3 sm:flex sm:px-6">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold shadow-lg hover:bg-gray-50"
            onClick={() => onClose(false)}
          >
            Done
          </button>
        </div>
      </div>
    </Dialog>
  );
}

type Source = "text" | "document" | "video" | "url";

function Generate() {
  const [source, setSource] = useState<Source>("text");
  const [drop, setDrop] = useState(false);

  return (
    <div className="grow flex flex-col justify-between py-4">
      <div className="relative flex flex-col gap-2 justify-center">
        <p className="text-sm font-semibold text-white mb-0">
          Learning Topic Source
        </p>
        <button
          type="button"
          className="inline-flex justify-between rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900 text-center"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setDrop(!drop)}
        >
          {source === "text" && (
            <div className="flex flex-row gap-2">
              <ImportSvg width={20} height={20} />
              <span className="font-semibold">Text Input</span>
            </div>
          )}
          {source === "document" && (
            <div className="flex flex-row gap-2">
              <DocumentSvg width={20} height={20} />
              <span className="font-semibold">Document</span>
            </div>
          )}
          {source === "video" && (
            <div className="flex flex-row gap-2">
              <VideoSvg width={20} height={20} />
              <span className="font-semibold">Video</span>
            </div>
          )}
          {source === "url" && (
            <div className="flex flex-row gap-2">
              <LinkSvg width={20} height={20} />
              <span className="font-semibold">URL</span>
            </div>
          )}
          <ArrowSvg height={20} />
        </button>
        {drop && (
          <div className="absolute right-0 top-16 w-full origin-top-right rounded-md bg-white shadow-lg">
            <div className="py-1 divide-y">
              <button
                className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-200"
                onClick={() => {
                  setSource("text");
                  setDrop(!drop);
                }}
              >
                Text Input
              </button>
              <button
                className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-200"
                onClick={() => {
                  setSource("document");
                  setDrop(!drop);
                }}
              >
                Document
              </button>
              <button
                className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-200"
                onClick={() => {
                  setSource("video");
                  setDrop(!drop);
                }}
              >
                Video
              </button>
              <button
                className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-200"
                onClick={() => {
                  setSource("url");
                  setDrop(!drop);
                }}
              >
                URL
              </button>
            </div>
          </div>
        )}
        <p className="text-sm font-semibold text-white mb-0">Learning topic</p>
        {source === "text" && (
          <textarea
            className="rounded-lg bg-white px-3 py-2 h-24 text-sm text-gray-900"
            placeholder="Enter particular topics"
            style={{ resize: "none" }}
          />
        )}
        {source === "document" && (
          <div className="flex items-center justify-center">
            <label
              htmlFor="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-32 rounded-lg cursor-pointer bg-white hover:bg-gray-100"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <CloudSvg
                  width={36}
                  height={36}
                  className="p-2 border border-solid border-1 rounded-full mb-2"
                />
                <p className="mb-2 text-sm text-blue-500">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs text-gray-400">PDF or PNG (Max 20mb)</p>
              </div>
              <input id="dropzone-file" type="file" className="hidden" />
            </label>
          </div>
        )}
        {source === "video" && (
          <>
            <textarea
              className="rounded-lg bg-white px-3 py-2 text-sm text-gray-900 font-semibold"
              placeholder="https://youtu.be/abcdef"
              style={{ resize: "none" }}
            />
            <p className="text-sm text-gray-300 text-center">
              Ensure the URL is a valid YouTube link
            </p>
          </>
        )}
        {source === "url" && (
          <textarea
            className="rounded-lg bg-white px-3 py-2 text-sm text-gray-900 font-semibold"
            placeholder="https://cui.studio"
            style={{ resize: "none" }}
          />
        )}
        <p className="text-sm font-semibold text-white mb-0">Grade level</p>
        <button
          type="button"
          className="inline-flex justify-between rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900 text-center"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          <div className="flex flex-row gap-2">
            <BookSvg width={20} height={20} />
            <p className="font-semibold">Grade level</p>
          </div>
          <ArrowSvg width={20} height={20} />
        </button>
      </div>
      <div className="flex flex-col gap-2 justify-center">
        <button className="py-2 text-blue-500 shadow-2xl font-semibold bg-white rounded-lg">
          Generate
        </button>
        <span className="text-xs text-gray-300/75 text-center">
          <p>
            AI models may make mistakes.{" "}
            <a className="underline underline-1">Details.</a>
          </p>
        </span>
      </div>
    </div>
  );
}

function Refine({ openModal }: { openModal: (arg0: boolean) => void }) {
  const [drop, setDrop] = useState(false);

  return (
    <div className="grow flex flex-col justify-between py-4">
      <div className="relative flex flex-col gap-2 justify-center">
        <p className="text-sm font-semibold text-white mb-0">Edit Theme</p>
        <button
          type="button"
          className="inline-flex justify-between rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900 text-center"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setDrop((drop) => !drop)}
        >
          <div className="flex flex-row gap-2">
            <BucketSvg width={20} height={20} />
            <p className="font-semibold">Standard Purple</p>
          </div>
          <ArrowSvg width={20} height={20} />
        </button>
        <p className="text-sm font-semibold text-white mb-0">
          Curriculum Standards
        </p>
        <button
          type="button"
          className="relative inline-flex justify-center rounded-lg bg-white px-3 py-2 text-sm font-semibold text-blue-500 text-center"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => openModal(true)}
        >
          <FlashSvg
            width={26}
            height={26}
            className="absolute left-2 inset-y-1"
          />
          <p className="font-semibold">Add New</p>
        </button>
        <p className="text-sm font-semibold text-white mb-0">
          Learning Objectives / Student Outcomes
        </p>
        <textarea
          className="rounded-lg bg-white px-3 py-2 h-24 text-sm text-gray-900 resize-none"
          placeholder="Students will..."
        />
      </div>
      <div className="flex flex-col gap-2 justify-center">
        <button className="py-2 shadow-2xl font-semibold bg-white rounded-lg">
          Generate Alternate Version
        </button>
        <span className="text-xs text-gray-300/75 text-center">
          <p>
            Changes the questions indead of learning topics in order to ensure
            academic integrity.
          </p>
        </span>
      </div>
    </div>
  );
}

function Publish() {
  const [drop, setDrop] = useState(false);

  return (
    <div className="grow flex flex-col justify-between py-4">
      <div className="relative flex flex-col gap-2 justify-center">
        <p className="text-sm font-semibold text-white mb-0">Export As...</p>
        <button
          type="button"
          className="inline-flex justify-between rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900 text-center"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setDrop((drop) => !drop)}
        >
          <div className="flex flex-row gap-2">
            <UploadSvg width={20} height={20} />
            <p className="font-semibold">PDF</p>
          </div>
          <ArrowSvg width={20} height={20} />
        </button>
        <p className="text-sm font-semibold text-white mb-0">
          Create New Listing
        </p>
        <button className="py-2 shadow-2xl font-semibold bg-white rounded-lg text-blue-500">
          Publish to Marketplace
        </button>
        <span className="text-xs text-gray-300/75 text-center">
          <p>
            This will save your worksheet to your account and take you to the
            listings page.
          </p>
        </span>
      </div>
    </div>
  );
}

type Tab = "generate" | "refine" | "publish";

const Remix = observer(() => {
  const [tab, setTab] = useState<Tab>("generate");
  const project = useProject();
  const { store } = project;

  const heightRef = useRef<HTMLDivElement>(null);

  const size = useSize(heightRef);

  return (
    <>
      <div ref={heightRef} style={{ width: "0", height: "100%" }}></div>
      <Button
        // className="flex flex-row gap-2 bg-blue-600 text-white rounded-lg p-2 items-center"
        onClick={() => project.toggleRemixSideBar()}
        // onClick={() => store.openSidePanel("stable-diffusion")}
        type="button"
        intent="primary"
        icon={<RefineSvg style={{ display: "inline-block", height: "16px" }} />}
      >
        <span>Refine</span>
      </Button>
      <Drawer
        position="right"
        isOpen={project.remixSideBarOpen}
        onClose={() => project.setRemixSideBarOpen(false)}
        canOutsideClickClose={false}
        hasBackdrop={false}
        autoFocus={false}
        enforceFocus={false}
        shouldReturnFocusOnClose={false}
        className="w-[var(--sidebar-open-shift,_100vw)] max-h-screen bg-primary rounded-l-3xl flex flex-col px-8 gap-4 overflow-y-auto"
        style={{ marginTop: size?.height && `${size.height}px` }}
        title={
          <div className="flex-none flex flex-row items-center justify-center pt-8">
            <RefineSvg width={36} height={36} className="mr-2" />
            <p className="text-white font-semibold text-xl max-w-full text-wrap text-center">
              Add or Change Content
            </p>
          </div>
        }
      >
        <RemixInterior 
          store={store}
        />
        {/* </div> */}
      </Drawer>
    </>
  );
});

export const RemixInterior = ({ store }: { store: StoreType }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedStandards, setSelectedStandards] = useState<LearningStandardEntries>([]);

  return (
    <>
        <RegenSegment store={store} selectedStandards={selectedStandards}>
          <LabelledInput
            label="Curriculum Standards"
            id="curriculum-standard-menu-button"
          >
            <Button
              type="button"
              // className="relative inline-flex justify-center rounded-lg bg-white px-3 py-2 text-sm font-semibold text-blue-500 text-center"
              className="w-full font-semibold text-primary text-center"
              id="curriculum-standard-menu-button"
              aria-expanded={showModal}
              aria-haspopup="true"
              onClick={() => setShowModal(true)}
            >
              <span className="font-semibold">Add New</span>
            </Button>
          </LabelledInput>
          <div className="flex flex-row flex-wrap gap-2">
            {selectedStandards.map((entry) => (
              <span
                key={entry.value}
                className="inline-flex gap-1 text-white text-sm font-semibold pl-3 pr-2 py-0.5 bg-sky-500 rounded-full text-center mb-0"
              >
                <span>
                  {entry.label}
                </span>
                <Icon
                  icon="small-cross"
                  className="inline-flex items-center cursor-pointer"
                  svgProps={{ className: "inline-flex items-center rounded-full hover:bg-black/10" }}
                  role="button"
                  onClick={() => {
                    setSelectedStandards((prev) =>
                      prev.filter((e) => e.value !== entry.value)
                    );
                  }}
                />
              </span>
            ))}
          </div>
        </RegenSegment>
        {/* <div className="absolute bg-blue-500 right-0 w-96 rounded-l-3xl flex flex-col px-8 gap-4 h-screen"> */}
        {/* <div className="flex-none flex flex-row items-center justify-between">
          {tab === "generate" ? (
            <button
              className="text-white font-semibold underline decoration-solid decoration-2 underline-offset-8"
              onClick={() => setTab("generate")}
            >
              Generate
            </button>
          ) : (
            <button
              className="text-white font-semibold"
              onClick={() => setTab("generate")}
            >
              Generate
            </button>
          )}
          {tab === "refine" ? (
            <button
              className="text-white font-semibold underline decoration-solid decoration-2 underline-offset-8"
              onClick={() => setTab("refine")}
            >
              Refine
            </button>
          ) : (
            <button
              className="text-white font-semibold"
              onClick={() => setTab("refine")}
            >
              Refine
            </button>
          )}
          {tab === "publish" ? (
            <button
              className="text-white font-semibold underline decoration-solid decoration-2 underline-offset-8"
              onClick={() => setTab("publish")}
            >
              Publish
            </button>
          ) : (
            <button
              className="text-white font-semibold"
              onClick={() => setTab("publish")}
            >
              Publish
            </button>
          )}
        </div>
        {tab === "generate" && <Generate />}
        {tab === "refine" && <Refine openModal={setShowModal} />} */}
        {/* {tab === "publish" && <Publish />} */}
        <Modal
          show={showModal}
          onClose={setShowModal}
          selected={selectedStandards}
          setSelected={setSelectedStandards}
        />
    </>
  )
}

export default Remix;
