import { observer } from "mobx-react-lite";

import { SectionTab } from "polotno/side-panel";

import { useProject } from "../project";
import { BackwardItem } from "iconsax-react";

import { StoreType } from "polotno/model/store";
import { nanoid } from "nanoid";
import { MainSection } from "./main-section";
import TemplateSelector from "@/components/TemplateSelector";
import { RemixInterior } from "@/components/Remix";

export const TemplatesPanel = observer(({ store }: { store: StoreType }) => {
  const project = useProject();

  return (
    <div className="flex flex-col h-full gap-4 bg-primary p-4">
      <div className="flex flex-row justify-center items-center gap-4">
        <div className="size-20 rounded-2xl shadow-[0_5px_15px_10px_rgba(0,0,0,0.1)]">
          <MainSection.Tab />
        </div>
        <div className="flex-1 flex flex-col justify-center text-center text-2xl text-primary-foreground font-bold">
          Welcome to the TeachShare Creator!
        </div>
      </div>

      <div className="text-primary-foreground">
        Select a template to get started, or click the logo above for more
        options (including an advanced mode with even more features!)
      </div>

      <TemplateSelector selectedTemplate={store.activePage?.custom?.template} setSelectedTemplate={(template) => {
        project.runWithLoading(async () => {
          const json = await fetch(
            `/static/jsons/${template}.json`
          )
            .then((res) => res.json()) as (
              Omit<ReturnType<typeof store.toJSON>, "pages"> & {
                width: number;
                height: number;
                pages: {
                  id: string;
                  width: number | "auto";
                  height: number | "auto";
                  children: { id: string }[];
                  custom: {
                    [key: string]: any;
                  };
                }[];
              }
            );
          // store.loadJSON(item.json);
          const { width, height } = json;
          const groupId = nanoid(5);
          store.loadJSON({
            ...json,
            pages: json.pages.map((page, index) => ({
              ...page,
              width: !page.width || page.width === "auto" ? width : page.width,
              height: !page.height || page.height === "auto" ? height : page.height,
              custom: {
                ...page.custom,
                templatePageIndex: index,
                groupId,
              },
            }))
          });
        })
      }} />

      <RemixInterior store={store} />
    </div>
  );
});

export const BasicModeSection = {
  name: "basic-mode",
  Tab: (props) => (
    <SectionTab name="Basic Mode" {...props}>
      <BackwardItem variant="Linear" className="inline" />
    </SectionTab>
  ),
  Panel: TemplatesPanel,
};
