export const LEGACY_TEMPLATES_CATEGORIES = [
  {
    value: "",
    label: "All",
  },
  {
    value: "text",
    label: "Text",
  },
  {
    value: "visual",
    label: "Visual",
  },
  {
    value: "graphic_organizer",
    label: "Graphic Organizer",
  },
  {
    value: "numbers",
    label: "Numbers",
  },
] as const;

export const LEGACY_TEMPLATES = [
  {
    "title": "Classic Q&A",
    "description": "A simple question and answer template.",
    "details": "The classic WorksheetsAI experience!",
    "value": "legacy_questions_answers",
    "categories": [
      "text"
    ],
    "enableForFreeTrial": true,
    "thumbnailPageIndex": "1"
  },
  {
    "title": "Main Idea by Paragraph",
    "description": "",
    "details": "",
    "value": "main_idea_by_paragraph",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "enableSource": true,
    "thumbnailPageIndex": "1"
  },
  {
    "title": "A.C.E. Short Answer",
    "description": "",
    "details": "",
    "value": "ace_short_answer",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "generatesImage": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "Three Row Notes",
    "description": "",
    "details": "",
    "value": "three_row_notes",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "generatesImage": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "Problems & Solutions",
    "description": "",
    "details": "",
    "value": "problems_and_solutions",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "generatesImage": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "Explain an Event",
    "description": "",
    "details": "",
    "value": "explain_an_event",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "enableSource": true,
    "thumbnailPageIndex": "1"
  },
  {
    "title": "Fill in the Blank Word Bank with Images",
    "description": "Fill in the blank with the correct word from the word bank",
    "details": "Each page has 5 words with an image and a fill-in-the-blank sentence. The words and images are in a word bank at the top of the page.",
    "value": "fill_in_the_blank_word_bank_with_images",
    "categories": [
      "visual",
      "text"
    ],
    "generatesImage": true,
    "thumbnailPageIndex": "1"
  },
  {
    "title": "Tracing Phrase",
    "description": "Trace the phrase",
    "details": "Each page has a phrase to trace.",
    "value": "tracing_phrase",
    "categories": [
      "text"
    ],
    "thumbnailPageIndex": "1"
  },
  {
    "title": "Trace Word & Complete Sentence",
    "description": "Trace the word and complete the sentence",
    "details": "Each page has a word to trace and a sentence to complete.",
    "value": "mkp_trace_word_complete_sentence",
    "categories": [
      "text",
      "visual"
    ],
    "thumbnailPageIndex": "1"
  },
  {
    "title": "Trace Letter, Vocab Word",
    "description": "Trace the letter and the vocab word",
    "details": "Each page has a letter and a vocab word to trace.",
    "value": "mkp_trace_letter_vocab_word",
    "categories": [
      "text",
      "visual"
    ],
    "thumbnailPageIndex": "1"
  },
  {
    "title": "Trace Vocab Word",
    "description": "Trace the vocab word",
    "details": "Each page has a vocab word to trace.",
    "value": "mkp_trace_vocab_word",
    "categories": [
      "text",
      "visual"
    ],
    "thumbnailPageIndex": "1"
  },
  {
    "title": "Tracing Number",
    "description": "Trace the number",
    "details": "Each page has one number to trace.",
    "value": "tracing_number",
    "categories": [
      "text",
      "numbers"
    ],
    "thumbnailPageIndex": "1"
  },
  {
    "title": "Trace & Print the Words",
    "description": "4 objects for tracing, with traceable labels.",
    "details": "The images and words are themed around the given theme.",
    "value": "tracing_objects_words",
    "categories": [
      "visual",
      "text"
    ],
    "generatesImage": true,
    "thumbnailPageIndex": "1"
  },
  {
    "title": "Tracing Objects",
    "description": "6 objects for tracing, with a funny title and directions.",
    "details": "The images are themed around the given theme.",
    "value": "tracing_objects",
    "categories": [
      "visual"
    ],
    "generatesImage": true,
    "thumbnailPageIndex": "1"
  },
  {
    "title": "Tracing Letters Two Letters",
    "description": "Trace two letters",
    "details": "Each page has two letters to trace.",
    "value": "tracing_letters_two_letters",
    "categories": [
      "text"
    ],
    "thumbnailPageIndex": "1"
  },
  {
    "title": "Tracing Letters One Letter",
    "description": "Trace one letter",
    "details": "Each page has one letter to trace.",
    "value": "tracing_letters_one_letter",
    "categories": [
      "text"
    ],
    "thumbnailPageIndex": "1"
  },
  {
    "title": "CVC Scramble",
    "description": "Unscramble the CVC word",
    "details": "Each page has a CVC word to unscramble.",
    "value": "cvc_scramble",
    "categories": [
      "text",
      "visual"
    ],
    "thumbnailPageIndex": "1"
  },
  {
    "title": "Story Timeline",
    "description": "An image and story passage, with an empty timeline for students to fill in.",
    "details": "Multiple images and stories can be provided, which will create mutliple pages.",
    "value": "story_timeline",
    "categories": [
      "text"
    ],
    "generatesImage": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "Image Coloring Page",
    "description": "Color the image",
    "details": "Each page has an image for coloring.",
    "value": "image_coloring_page",
    "categories": [
      "visual"
    ],
    "generatesImage": true,
    "thumbnailPageIndex": "1"
  },
  {
    "title": "Matching Image to Word",
    "description": "Match the image to the word",
    "details": "Each page has 5 images with a word bank at the bottom of the page.",
    "value": "matching_image_word",
    "categories": [
      "visual"
    ],
    "generatesImage": true,
    "thumbnailPageIndex": "1"
  },
  {
    "title": "Short Story and Questions",
    "description": "A short story with 7 short answer questions, and an image.",
    "details": "The story and questions are themed around the given theme.",
    "value": "story_questions",
    "categories": [
      "text"
    ],
    "generatesImage": true,
    "thumbnailPageIndex": "1"
  },
  {
    "title": "PEEL Short Answer",
    "description": "",
    "details": "",
    "value": "peel_short_answer",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "generatesImage": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "KWL",
    "description": "",
    "details": "",
    "value": "kwl",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "generatesImage": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "Claims & Counterclaims",
    "description": "",
    "details": "",
    "value": "claims_and_counterclaims",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "generatesImage": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "Fill in the Blank and Complete the Word",
    "description": "Fill in the blank and complete the word",
    "details": "Each page has a word with a missing letter to fill in.",
    "value": "fitb_and_complete_word",
    "categories": [
      "text",
      "visual"
    ],
    "thumbnailPageIndex": "1"
  },
  {
    "title": "First, Then, Finally",
    "description": "",
    "details": "",
    "value": "first_then_finally",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "enableForFreeTrial": true,
    "enableSource": true,
    "thumbnailPageIndex": "1"
  },
  {
    "title": "Main Idea by Passage",
    "description": "",
    "details": "",
    "value": "main_idea_by_passage",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "enableSource": true,
    "thumbnailPageIndex": "1"
  },
  {
    "title": "SOAPSTONE",
    "description": "",
    "details": "",
    "value": "soapstone",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "generatesImage": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "Story Elements",
    "description": "",
    "details": "",
    "value": "story_elements",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "generatesImage": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "TWIST",
    "description": "",
    "details": "",
    "value": "twist",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "generatesImage": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "Cause/Effect",
    "description": "",
    "details": "",
    "value": "cause_effect",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "generatesImage": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "Text to Text/Self/World",
    "description": "",
    "details": "",
    "value": "text_to_text_self_world",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "generatesImage": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "Five Senses",
    "description": "",
    "details": "",
    "value": "five_senses",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "generatesImage": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "Claims & Evidence",
    "description": "",
    "details": "",
    "value": "claims_and_evidence",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "generatesImage": true,
    "enableForFreeTrial": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "C.E.R. Short Answer",
    "description": "",
    "details": "",
    "value": "cer_short_answer",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "generatesImage": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "ACAPS Analysis",
    "description": "",
    "details": "",
    "value": "acaps_analysis",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "generatesImage": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
  {
    "title": "Visual Summary",
    "description": "",
    "details": "",
    "value": "visual_summary",
    "categories": [
      "text",
      "graphic_organizer"
    ],
    "enableForFreeTrial": true,
    "enableSource": true,
    "thumbnailPageIndex": "2"
  },
] as const;
