import { cn } from "@/lib/utils";

const Loading = (props: { className?: string }) => {
    return (
        <div
            className={cn(
                "inline-block h-10 w-10 animate-spin justify-self-center rounded-full border-[3px] border-current border-t-transparent",
                props.className,
            )}
            role="status"
            aria-label="loading"
        >
            <span className="sr-only">Loading...</span>
        </div>
    );
};

export default Loading;
