import { observer } from "mobx-react-lite";

import { SectionTab } from "polotno/side-panel";
import type { StoreType } from "polotno/model/store";

import { useProject } from "../project";
import { DocumentText } from "iconsax-react";
import Quill from "quill";
import type QuillType from "quill";
import { Button, FormGroup, TextArea } from "@blueprintjs/core";
import { PropsWithChildren, useEffect, useState } from "react";

import { quillRef } from "polotno/canvas/html-element";
import type { QuestionContainerBlot } from "../EditorApp";
import { QuestionParagraphBlot } from "../EditorApp";
import { ActivePageCustomCheckbox } from "../ai-magic";

const formatCurrent = (format: string, value: string, unsetIfSet = false) => {
  let quill = (window as unknown as { __polotnoQuill: QuillType })
    .__polotnoQuill;
  if (!quill) {
    console.warn("No quill instance found");
    return;
  }

  const realValue = unsetIfSet ? !quill.getFormat()[format] && value : value;

  quill.format(format, realValue, "user");
};

const getQuill = () => {
  const quill = (window as unknown as { __polotnoQuill: QuillType })
    .__polotnoQuill;
  if (!quill) {
    console.warn("No quill instance found");
    return;
  }
  return quill;
};

const getCurrentContainer = () => {
  const quill = getQuill();
  if (!quill) return;

  const selection = quill.getSelection();
  if (!selection) return;

  let { index, length } = selection;
  length ||= 1;

  const [first, ...rest] = quill.getLines(index, length);

  const last = rest.pop();
  const check = [first, last].filter((blot) => blot);
  const nodes = check.map((blot) =>
    blot.domNode?.closest("div.questioncontainer")
  );
  const oneContainerParent = nodes.every((val, _i, arr) => val === arr[0]);
  const filteredNodes = nodes.filter((blot) => blot);
  if (!oneContainerParent || filteredNodes.length === 0) return;

  const [container] = filteredNodes;
  const containerBlot = Quill.find(container) as InstanceType<
    typeof QuestionContainerBlot
  >;
  return containerBlot;
};

const formatCurrentData = (
  format: string,
  value: string,
  unsetIfSetExact = false
) => {
  const quill = getQuill();
  if (!quill) return;

  const containerBlot = getCurrentContainer();
  if (!containerBlot) return;

  const head = containerBlot.children.head as InstanceType<
    typeof QuestionParagraphBlot
  > | null;
  if (!head) return;

  const headFormats = QuestionParagraphBlot.formats(head.domNode);
  const realValue =
    unsetIfSetExact && headFormats[format] === value ? "" : value;

  head.format(format, realValue);
};

const ToggleButton = observer(
  <F extends string>({
    format,
    value = "true",
    icon,
    children,
    className,
    disabled,
    isDataFormat = false,
    toggle = true,
  }: PropsWithChildren<{
    active?: boolean;
    format: F;
    value?: string;
    icon: Parameters<typeof Button>[0]["icon"];
    className?: string;
    disabled?: boolean;
    isDataFormat?: boolean;
    toggle?: boolean;
  }>) => {
    const formats = quillRef.currentFormat as unknown as {
      ["questioncontainer-para"]?: { [key in F]: string };
    } & {
      [format: string]: string;
    }

    const currentFormatValue = isDataFormat
      ? formats?.["questioncontainer-para"]?.[format] || ""
      : formats[format];

    const isActive = currentFormatValue === value || (
      format === "questioncontainer" && formats["questioncontainer-para"]
    );

    return (
      <Button
        minimal
        icon={icon}
        active={isActive}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        onClick={() => {
          if (isDataFormat) {
            formatCurrentData(format, value, toggle);
          } else {
            formatCurrent(format, value, toggle);
          }
        }}
        className={className}
        disabled={disabled}
      >
        {children}
      </Button>
    );
  }
);

const InsertEmbedButton = observer(
  ({
    type,
    value,
    children,
    icon,
    className,
    disabled,
  }: {
    type: string;
    value: any;
    children: React.ReactNode;
    icon: Parameters<typeof Button>[0]["icon"];
    className?: string;
    disabled?: boolean;
  }) => {
    return (
      <Button
        icon={icon}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        onClick={() => {
          const quill = getQuill();
          if (!quill) return;

          const selection = quill.getSelection();
          if (!selection) return;

          quill.insertEmbed(selection.index, type, value);
        }}
        className={className}
        disabled={disabled}
      >
        {children}
      </Button>
    );
  }
);

const getCurrentCorrectAnswer = (currentFormat: {}) =>
  (
    currentFormat as unknown as {
      ["questioncontainer-para"]: { correctanswer: string };
    }
  )?.["questioncontainer-para"]?.correctanswer || "";

export const TemplatesPanel = observer(({ store }: { store: StoreType }) => {
  const project = useProject();

  useEffect(() => {
    if (project.store.openedSidePanel === QUESTION_ANSWER_SECTION_NAME) {
      document.body.classList.add("question-answer-mode");
    } else {
      document.body.classList.remove("question-answer-mode");
    }
  }, [project.store.openedSidePanel]);

  const isQuillOpen = !!quillRef.editor.instance;
  const currentFormat = quillRef.currentFormat;
  const currentInsideContainer = !!getCurrentContainer();

  const [correctAnswer, setCorrectAnswer] = useState("");

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <h2 className="text-xl font-bold">Interactive Q&A Configuration</h2>
      <ToggleButton
        active={false}
        format="questioncontainer"
        icon="group-objects"
        className="questioncontainer"
        disabled={!isQuillOpen}
      >
        Mark Question (w/ Answers) Section
      </ToggleButton>
      <ToggleButton
        active={false}
        format="question"
        icon="help"
        className="question-true"
        disabled={!currentInsideContainer}
      >
        Question Text
      </ToggleButton>
      <ToggleButton
        active={false}
        format="answer"
        icon="hand-up"
        className="answer-true"
        disabled={!currentInsideContainer}
      >
        Mark Answer
      </ToggleButton>
      <h3 className="font-semibold">Question Type</h3>
      <ToggleButton
        active={false}
        format="type"
        value="mcq"
        icon="form"
        className="type-mcq"
        isDataFormat
        disabled={!currentInsideContainer}
        toggle={false}
      >
        MCQ
      </ToggleButton>
      <ToggleButton
        active={false}
        format="type"
        value="fitb"
        icon="bring-data"
        className="type-fitb"
        isDataFormat
        disabled={!currentInsideContainer}
        toggle={false}
      >
        FITB
      </ToggleButton>
      <ToggleButton
        active={false}
        format="type"
        value="short"
        icon="equals"
        className="type-short"
        isDataFormat
        disabled={!currentInsideContainer}
        toggle={false}
      >
        Short Answer
      </ToggleButton>
      <ToggleButton
        active={false}
        format="type"
        value="long"
        icon="lengthen-text"
        className="type-long"
        isDataFormat
        disabled={!currentInsideContainer}
        toggle={false}
      >
        Long Answer
      </ToggleButton>
      <FormGroup
        label="Correct Answer Editor"
        labelFor="correctanswer"
        className="font-semibold"
      >
        <TextArea
          id="correctanswer"
          name="correctanswer"
          className="w-full"
          value={correctAnswer}
          onChange={(e) => setCorrectAnswer(e.target.value)}
        />
        <div className="grid grid-cols-2 gap-4 mt-4">
          <Button
            outlined
            onClick={() => {
              setCorrectAnswer(getCurrentCorrectAnswer(currentFormat));
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            disabled={
              !(
                currentInsideContainer &&
                getCurrentCorrectAnswer(currentFormat) !== correctAnswer
              )
            }
          >
            Load Q @ Cursor
          </Button>
          <Button
            onClick={() => {
              formatCurrentData("correctanswer", correctAnswer);
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            disabled={
              !(
                currentInsideContainer &&
                getCurrentCorrectAnswer(currentFormat) !== correctAnswer
              )
            }
            active={getCurrentCorrectAnswer(currentFormat) === correctAnswer}
          >
            Save
          </Button>
        </div>
      </FormGroup>
      <InsertEmbedButton
        type="hiddenseparator"
        value="true"
        icon="minus"
        // className="hiddenseparator"
        disabled={!isQuillOpen}
      >
        Insert Question Separator
      </InsertEmbedButton>
      <div>
        <ActivePageCustomCheckbox
          store={store}
          valKey="hideInInteractive"
          label="Hide Page in Interactive"
        />
      </div>
    </div>
  );
});

const QUESTION_ANSWER_SECTION_NAME = "questions-answers";

export const QuestionsAnswersSection = {
  name: QUESTION_ANSWER_SECTION_NAME,
  Tab: (props) => (
    <SectionTab name="Q&A" {...props}>
      <DocumentText variant="Linear" className="inline" />
    </SectionTab>
  ),
  Panel: TemplatesPanel,
};
