"use client";

import { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Position,
  Menu,
  MenuItem,
  MenuDivider,
  Checkbox,
  ControlGroup,
} from "@blueprintjs/core";
import { Clean } from "@blueprintjs/icons";
import { Popover } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";

import { getAPI } from "polotno/utils/api";
import { t } from "polotno/utils/l10n";
import { getKey } from "polotno/utils/validate-key";
import { StoreType } from "polotno/model/store";
import type { ElementCustomValueBooleanKey, ElementCustomValueOptionsBooleanKey, ElementCustomValueStringKey, ElementWithCustom } from "./types";

export const SelectedElementsCustomCheckbox = observer(
  ({
    store,
    valKey,
    label,
    defaultValue = false,
  }: {
    store: StoreType;
    valKey: ElementCustomValueBooleanKey;
    label: string;
    defaultValue?: boolean;
  }) => {
    const elements = store.selectedElements as ElementWithCustom[];
    const all = elements.every((el) => el?.custom?.[valKey] ?? defaultValue);
    const none = elements.every((el) => !el?.custom?.[valKey] ?? defaultValue);
    return (
      <Checkbox
      	label={label}
        checked={all}
        indeterminate={!all && !none}
        onChange={(e) => {
          const { checked } = e.target;
          store.history.transaction(() => {
            for (const el of store.selectedElements) {
              el.set({ custom: { ...el.custom, [valKey]: checked } });
            }
          });
        }}
        className="my-auto"
      />
    );
  }
);

export const ActivePageCustomCheckbox = observer(
  ({
    store,
    valKey,
    label,
  }: {
    store: StoreType;
    valKey: ElementCustomValueBooleanKey;
    label: string;
  }) => (
    <Checkbox
      label={label}
      checked={!!store.activePage?.custom?.[valKey]}
      onChange={(e) => {
        store.activePage?.set({ custom: { ...store.activePage.custom, [valKey]: e.target.checked } });
      }}
      className="my-auto"
    />
  )
);

export const SelectedElementsCustomOptionCheckbox = observer(
  ({
    store,
    valKey,
    label,
  }: {
    store: StoreType;
    valKey: ElementCustomValueOptionsBooleanKey;
    label: string;
  }) => {
    const elements = store.selectedElements as ElementWithCustom[];
    const all = elements.every((el) => el?.custom?.options?.[valKey]);
    const none = elements.every((el) => !el?.custom?.options?.[valKey]);
    return (
      <Checkbox
        label={label}
        checked={all}
        indeterminate={!all && !none}
        onChange={(e) => {
          const { checked } = e.target;
          store.history.transaction(() => {
            for (const el of store.selectedElements) {
              el.set({ custom: { ...el.custom, options: { ...el.custom?.options, [valKey]: checked }} });
            }
          });
        }}
        className="my-auto"
      />
    );
  }
);

export const SelectedElementsCustomSelect = observer(
  <T extends ElementCustomValueStringKey, O extends {
    label: string;
    value: ElementWithCustom["custom"][T];
  }[] >({
    store,
    valKey,
    label,
    options,
    extraCustom = () => ({}),
  }: {
    store: StoreType;
    valKey: T;
    label: string;
    options: O;
    extraCustom?: (key: O[number], prevCustom: ElementWithCustom["custom"]) => ElementWithCustom["custom"];
  }) => {
    const elements = store.selectedElements as ElementWithCustom[];
    const first = elements[0]?.custom?.[valKey];
    const optionsMap = new Map(options.map((o) => [o.value, o.label]));
    const firstLabel = optionsMap.get(first);
    const all = elements.every((el) => el?.custom?.[valKey] === first);

    return (
      <Select
      
      items={options}
      itemRenderer={(item, { handleClick, modifiers }) => {
        return (
          <MenuItem
            key={item.value}
            text={item.label}
            onClick={handleClick}
            active={modifiers.active}
          />
        );
      }}
      onItemSelect={(item) => {
        store.history.transaction(() => {
          for (const el of store.selectedElements) {
            el.set({ custom: { ...el.custom, ...extraCustom(item, el.custom), [valKey]: item.value } });
          }
        });
      }}
      >
        <Button text={(all && firstLabel) || " "} rightIcon={"double-caret-vertical"} textClassName="min-w-24" />
      </Select>
    );
  })

export const AIMagicMenu = observer(({ store }: { store: StoreType }) => {
  return (
    <ControlGroup className="mx-2 space-x-2">
      <SelectedElementsCustomCheckbox
        store={store}
        valKey="dynamic"
        label="Dynamic"
        defaultValue={true}
      />
    </ControlGroup>
  );
});


export const AIMagicTextTypesMenu = observer(({ store }: { store: StoreType }) => {
  return (
    <>
    <ControlGroup className="mx-2 space-x-2">
      <SelectedElementsCustomSelect
        store={store}
        valKey="type"
        label="Type"
        options={[
          { label: "(None)", value: undefined },
          { label: "Title", value: "title" },
          { label: "Directions", value: "directions" },
          { label: "Passage", value: "passage" },
          { label: "Question Container", value: "question_container" },
          { label: "Question Text", value: "question_text" },
          { label: "Multiple Choice", value: "answer_mcq" },
          { label: "Fill-in-the-Blank Answer", value: "answer_fitb" },
          { label: "Short Answer", value: "answer_short" },
          { label: "Long Answer", value: "answer_long" },
        ] as const}
      />
    </ControlGroup>
    {
      store.selectedElements.length > 0 && store.selectedElements.every((el) => el.custom?.type === "question_container") && (
        <ControlGroup className="mx-2 space-x-2">
          <SelectedElementsCustomOptionCheckbox
            store={store}
            valKey="multiple"
            label="Multiple"
          />
        </ControlGroup>
      )
    }
    </>
  );
})