import { observer } from "mobx-react-lite";
import { useProject } from "../project";
import type { FC } from "react";
import Loading from "./Loading";

import { FaEnvelope, FaCheckCircle, FaExclamationCircle } from "@meronex/icons/fa";
import { FiSend } from "@meronex/icons/fi";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createReferral, getReferrals } from "@/api";

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"
import { LabelledInput } from "./arg_form";
import { Button, FormGroup, InputGroup, Tooltip } from "@blueprintjs/core";

// const ReferralDialog = observer(() => {
//     const project = useProject();

//     return (
//         <Dialog
//             isOpen={project.referralDialogOpen}
//             onClose={() => project.closeReferralDialog()}
//             className="w-[unset] p-8"
//         >
//             <div className="flex max-h-full w-full max-w-[32rem]">
//                 <div className="flex flex-col gap-3 text-center sm:text-left">
//                     <h2 className="pb-2 text-xl font-semibold leading-none tracking-tight text-primary text-center">
//                         Referral Dialog
//                     </h2>
//                 </div>
//             </div>
//         </Dialog>
//     )
// });

type ReferralStatus = "sent" | "joined" | "failed";

const IconTooltip = (props: { icon: FC<{ className?: string }>; text: string }) => (
    <Tooltip content={props.text} portalClassName="z-50">
        <props.icon className="h-8 w-8" />
    </Tooltip>
);

const ReferralStatusIcon = ({
    status,
}: {
    status: {
        loading: boolean;
        state: ReferralStatus;
        failedReason?: string;
    }
}) => {
    if (status.loading) {
        return <Loading className="h-8 w-8" />
    }

    if (status.state === "sent") {
        return <IconTooltip icon={FaEnvelope} text={"Email Sent!"} />
    }

    if (status.state === "joined") {
        return <IconTooltip icon={FaCheckCircle} text={"Joined!"} />
    }

    return <IconTooltip icon={FaExclamationCircle} text={status.failedReason ?? "Failed Sending: Unknown error"} />
};

const ReferralEntry = ({ email, state, loading, failedReason }: { email: string, state: ReferralStatus, loading: boolean, failedReason?: string }) => (
    <div className="flex items-center justify-between py-1 first:pt-0 last:pb-0">
        <span>{email}</span>
        <ReferralStatusIcon status={{ state, loading, failedReason }} />
    </div>
)

const ReferralDialog = observer(() => {
    const project = useProject();

    const queryClient = useQueryClient()

    const { isPending, error, data } = useQuery({
        queryKey: ['referrals'],
        queryFn: async () => {
            const referrals = await getReferrals();
            project.fetchDemoWorksheetsLeft();
            return referrals;
        },
    })

    const addReferralMutation = useMutation({
        mutationFn: (email: string) => createReferral({ email }).then((res) => {
            if (!res.success) {
                if (res.existing) {
                    throw new Error("Someone has already referred this email")
                }
                throw new Error(`Failed to create referral${res.failureReason ? `: ${res.failureReason}` : ""}`)
            }
            project.fetchDemoWorksheetsLeft();
            return res;
        }),
        onSettled: () => queryClient.invalidateQueries({ queryKey: ['referrals'] })
    })

    console.log("data", data)
    console.log("vars", addReferralMutation.variables)

    return (
        <Dialog
            open={project.referralDialogOpen}
            onOpenChange={() => project.closeReferralDialog()}
        // className="w-[unset] p-8"
        >
            <DialogContent>
                <form
                    className="flex flex-col gap-4"
                    onSubmit={(e) => {
                        e.preventDefault();

                        if (isPending) return;

                        const formData = new FormData(e.currentTarget as HTMLFormElement);
                        const email = formData.get("refer_email") as string;
                        if (!email) return;

                        addReferralMutation.mutate(email);
                    }}
                >
                    <DialogHeader>
                        <DialogTitle>Refer a Friend!</DialogTitle>
                        <DialogDescription className="text-base">
                            When one of your friends signs up, you both get refines for free!
                        </DialogDescription>
                    </DialogHeader>
                    <div className="flex flex-col divide-y">
                        {error && <div className="text-red-500">Something went wrong: {error.message}</div>}
                        {
                            isPending ? <Loading className="h-8 w-8" /> :
                                data?.referrals.map(({ id, email, joined }) => (
                                    <ReferralEntry key={id} email={email} state={joined ? "joined" : "sent"} loading={false} />
                                ))
                        }
                        {addReferralMutation.isError ? <ReferralEntry email={addReferralMutation.variables} state="failed" loading={false} failedReason={addReferralMutation.error?.message} /> : (
                            addReferralMutation.isPending && <ReferralEntry email={addReferralMutation.variables} state="sent" loading={true} />
                        )}
                    </div>
                    <FormGroup label="Email" labelFor="refer_email" className="m-0">
                        <InputGroup
                            name="refer_email"
                            type="email"
                            defaultValue=""
                            className="max-w-full"
                            placeholder="example@domain.com"
                            required={true}
                            pattern={import.meta.env.PROD ? "^[^+]*$" : undefined}
                        />
                    </FormGroup>
                    <Button disabled={isPending} type="submit">
                        <div className="flex flex-row items-center gap-1">
                            <FiSend className="h-4 w-4" />
                            <span>Send</span>
                        </div>
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    );
});

export default ReferralDialog;
