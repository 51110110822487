import { Button, NavbarGroup } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";
import { StoreType } from "polotno/model/store";
import { HistoryButtons } from "polotno/toolbar/history-buttons";

import { useProject } from "../project";

const CustomHistory = observer(({ store }: { store: StoreType }) => {
  const project = useProject();

  return (
    <>
      <NavbarGroup align={"left"} style={{ paddingRight: "10px" }}>
        <Button
          title={`Switch to ${project.basicMode ? "Advanced" : "Basic"} Mode`}
          icon="lightning"
          minimal
          active={!project.basicMode}
          onClick={() => project.setBasicMode(!project.basicMode)}
        />
      </NavbarGroup>
      <HistoryButtons store={store} />
    </>
  );
});

export default CustomHistory;
