"use client";

import * as mobx from "mobx";
import { createContext, useContext } from "react";
import localforage from "localforage";
import { signIn } from "./auth/react";

import * as api from "./api";
import { type StoreType } from "polotno/model/store";
import type { Args, Topic } from "./types";
import {
  copyWithNewIds,
  RecursiveWithId,
  signInWithGooglePopup,
} from "./utils";
import { AppToaster } from "./App";

import LEGACY_QA_TEMPLATE from "./legacy_questions_answers.json";

window.localforage = localforage;

export const ProjectContext = createContext<Project>({});

export const useProject = () => useContext(ProjectContext);

export const getFromStorage = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

export const setToStorage = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {}
};

const DEFAULT_GROUP_ID = "default";
export const DEFAULT_STORE = {
  ...LEGACY_QA_TEMPLATE,
  pages: LEGACY_QA_TEMPLATE.pages.map((page, index) => ({
    ...page,
    custom: {
      ...page.custom,
      templatePageIndex: index,
      groupId: DEFAULT_GROUP_ID,
    },
  })),
};

export const DEFAULT_DEMO_WORKSHEETS_LEFT = 1;

export type Flag = "interactive-export";

class Project {
  id = "";
  name = "";
  user = {};
  skipSaving = false;
  cloudEnabled = false;
  status = "saved"; // or 'has-changes' or 'saving' or 'loading'
  language = getFromStorage("polotno-language") || navigator.language || "en";
  designsLength = 0;

  remixSideBarOpen = true;

  store: StoreType;

  newPageTopic?: Topic | null = null;
  newPageArgs?: Args | null = null;

  tempOverridablePages: string[] = [];

  loginDialogOpen = false;
  pricingTableOpen = false;
  cancelSurveyOpen = false;
  subscriptionActive = false;

  demoMode = false;
  demoWorksheetsLeft = DEFAULT_DEMO_WORKSHEETS_LEFT;
  demoDialogOpen = false;

  referralDialogOpen = false;

  basicMode = false;

  enableTour = true;

  loading = false;

  flags: Flag[] = [];

  constructor({ store, cloudEnabled = false, demoMode = false, enableTour = true, flags = [] }: { store: StoreType, cloudEnabled?: boolean, demoMode?: boolean, enableTour?: boolean, flags?: Flag[] }) {
    mobx.makeAutoObservable(this);
    this.store = store;
    this.cloudEnabled = cloudEnabled;
    this.demoMode = demoMode;

    this.enableTour = enableTour;

    this.basicMode = getFromStorage("polotno-basic-mode") !== "false";

    store.on("change", () => {
      this.requestSave();
    });

    this.flags = flags;

    // setInterval(() => {
    //   mobx.runInAction(() => {
    //     this.cloudEnabled = false; // window.puter?.auth?.isSignedIn();
    //   });
    // }, 100);

    // mobx.reaction(
    //   () => store._activePageId,
    //   () => {
    //     mobx.runInAction(() => {
    //       this.newPageTopic = store.activePage.custom?.topic;
    //       this.newPageArgs = store.activePage.custom?.args;
    //     });
    //   }
    // );
  }

  async fetchDemoWorksheetsLeft() {
    const { trialWorksheetsLeft } = await api.getTrialWorksheetsLeft();
    mobx.runInAction(() => {
      this.demoWorksheetsLeft = trialWorksheetsLeft;
    });
  }

  openReferralDialog() {
    this.referralDialogOpen = true;
  }

  closeReferralDialog() {
    this.referralDialogOpen = false;
  }

  setLanguage(lang) {
    this.language = lang;
    setToStorage("polotno-language", lang);
  }

  requestSave() {
    this.status = "has-changes";
    if (this.saveTimeout) {
      return;
    }
    this.saveTimeout = setTimeout(() => {
      this.saveTimeout = null;
      // skip autosave if no project opened
      this.save();
    }, 5000);
  }

  async deprecateDesign() {
    await localforage.setItem("polotno-state", this.store.toJSON());
  }

  async firstLoad() {
    this.loading = true;
    const deprecatedDesign = await localforage.getItem("polotno-state");
    if (deprecatedDesign) {
      this.store.loadJSON(deprecatedDesign);
      await localforage.removeItem("polotno-state");
      await this.save();
      mobx.runInAction(() => {
        this.loading = false;
      });
      // location.reload();
      return;
    }
    const lastDesignId = await localforage.getItem("polotno-last-design-id");
    if (lastDesignId) {
      await this.loadById(lastDesignId);
    } else {
      await this.createNewDesign();
    }
    mobx.runInAction(() => {
      this.loading = false;
    });
  }

  async loadById(id) {
    this.id = id;
    await localforage.setItem("polotno-last-design-id", id);
    mobx.runInAction(() => {
      this.status = "loading";
    });
    if (!this.cloudEnabled) {
      await this.createNewDesign();
      return;
    }
    try {
      const { storeJSON, name } = await api.loadById({
        id,
      });
      if (storeJSON) {
        this.store.loadJSON(storeJSON);
      }
      mobx.runInAction(() => {
        this.name = name;
      });
    } catch (e) {
      console.error(e);
      mobx.runInAction(() => {
        this.id = "";
        this.name = "Untitled Design";
      });
      await localforage.removeItem("polotno-last-design-id");
    }
    mobx.runInAction(() => {
      this.status = "saved";
    });
  }

  updateUrlWithProjectId() {
    if (!this.id || this.id === "local") {
      window.history.replaceState({}, null, `/`);
      return;
    }
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    params.set("id", this.id);
    window.history.replaceState({}, null, `/design/${this.id}`);
  }

  async save() {
    if (!this.cloudEnabled) {
      return;
    }
    this.status = "saving";
    const storeJSON = this.store.toJSON();
    const maxWidth = 200;
    const canvas = await this.store._toCanvas({
      pixelRatio: maxWidth / this.store.activePage?.computedWidth,
      pageId: this.store.activePage?.id,
    });
    const blob = await new Promise((resolve) => {
      canvas.toBlob(resolve, "image/jpeg", 0.9);
    }) as Blob | null;
    try {
      const res = await api.saveDesign({
        storeJSON,
        preview: blob,
        id: this.id,
        name: this.name,
      });
      if (res.status === "saved") {
        mobx.runInAction(() => {
          this.id = res.id;
        });
        await localforage.setItem("polotno-last-design-id", res.id);
      }
    } catch (e) {
      console.error(e);
    }
    mobx.runInAction(() => {
      this.status = "saved";
    });
  }

  async duplicate() {
    this.id = "";
    this.save();
  }

  async clear() {
    this.store.clear();
    await this.resetStoreToDefault();
  }

  async createNewDesign() {
    await this.clear();
    mobx.runInAction(() => {
      this.name = "Untitled Design";
      this.id = "";
    });
    console.log("saving");
    await this.save();
    console.log("saving done");
  }

  setRemixSideBarOpen(open: boolean) {
    if (open) {
      document.body.classList.add("remix-sidebar-open");
    } else {
      document.body.classList.remove("remix-sidebar-open");
    }
    this.remixSideBarOpen = open;
  }

  toggleRemixSideBar() {
    this.setRemixSideBarOpen(!this.remixSideBarOpen);
  }

  async resetStoreToDefault() {
    this.store.loadJSON({
      ...DEFAULT_STORE,
      pages: [],
    });
    this.addTempOverridablePages(DEFAULT_STORE.pages);
    await localforage.removeItem("polotno-last-design-id");
  }

  addTempOverridablePages(pages: RecursiveWithId[]) {
    const copiedPages = copyWithNewIds(...pages);
    const json = JSON.parse(JSON.stringify(this.store.toJSON())) as ReturnType<
      StoreType["toJSON"]
    >;

    if (this.store.activePage) {
      const activePageIndex = json.pages.findIndex(
        (p) => "id" in p && (p.id as string) === this.store.activePage.id
      );

      if (this.tempOverridablePages.length > 0) {
        const firstOverridablePageIndex = this.store.pages.findIndex(
          (p) => p.id === this.tempOverridablePages[0]
        );
        json.pages = json.pages.filter(
          (p) => "id" in p && !this.tempOverridablePages.includes(p.id as string)
        );
        json.pages.splice(firstOverridablePageIndex, 0, ...copiedPages);
      } else if (this.store.activePage.children.length === 0) {
        json.pages.splice(activePageIndex, 1, ...copiedPages);
      } else {
        json.pages.splice(activePageIndex + 1, 0, ...copiedPages);
      }
    } else {
      json.pages = copiedPages;
    }

    this.store.loadJSON(json, true);
    this.store.selectPage(copiedPages[0].id);
    this.tempOverridablePages = copiedPages.map((p) => p.id);

    setTimeout(() => {
      const dispose = mobx.reaction(
        () => this.store.selectedElements,
        () => {
          this.tempOverridablePages = [];
          dispose();
        }
      );
    });
  }

  setPricingTableOpen(open: boolean) {
    this.pricingTableOpen = open;
  }

  setSubscriptionActive(active: boolean) {
    this.subscriptionActive = active;
  }

  openLoginDialog() {
    if (!this.cloudEnabled) {
      this.loginDialogOpen = true;
    }
  }

  closeLoginDialog() {
    this.loginDialogOpen = false;
  }

  openCancelSurvey() {
    this.cancelSurveyOpen = true;
  }

  closeCancelSurvey() {
    this.cancelSurveyOpen = false;
  }

  async runWithLoading(
    fn: (() => Promise<void>) | (() => void),
    message = "Something went wrong, please try again later.",
  ) {
    this.loading = true;
    try {
      await fn();
    } catch (e) {
      AppToaster.then((toaster) =>
        toaster.show({
          intent: "danger",
          message,
        })
      );
      throw e;
    } finally {
      mobx.runInAction(() => {
        this.loading = false;
      });
    }
  }

  useDemoWorksheet() {
    this.demoWorksheetsLeft -= 1;
    this.fetchDemoWorksheetsLeft();
  }

  openDemoDialog() {
    this.demoDialogOpen = true;
  }

  closeDemoDialog() {
    this.demoDialogOpen = false;
  }

  setBasicMode(basicMode: boolean) {
    this.store.selectElements([]);
    this.basicMode = basicMode;
    setToStorage("polotno-basic-mode", basicMode.toString());
  }
}

export const projectSignIn = async (project: Project, update: () => Promise<any>) => {
  project.openLoginDialog();
    // await signInWithGooglePopup();
    // await update();
    // await window.puter.auth.signIn();
    // await mobx.runInAction(async () => {
      // await mobx
      //   .when(() => project.cloudenabled, { timeout: 10000 }) // todo: revisit, possible lower timeout
      //   .catch(() => {
      //     console.log("failed to sign in")
      //     console.log(project)
      //     console.log(project.cloudenabled)
      //     // location.reload();
      //   });
    //   project.designsLength = await api.backupFromLocalToCloud();
    // });
    // setTimeout(async () => {
    //   if (window.project.cloudEnabled) {
    //     await mobx.runInAction(async () => {
    //       project.designsLength = await api.backupFromLocalToCloud();
    //     });
    //   } else {
    //     console.warn("failed to sign in")
    //   }
    // }, 1000);
  }

export const createProject = (...args) => new Project(...args);
export default createProject;

export type ProjectType = ReturnType<typeof createProject>;
