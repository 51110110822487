"use client";

import { createStore, StoreType } from "polotno/model/store";
import { setTranslations, unstable_setAnimationsEnabled } from "polotno/config";
import { createProject, DEFAULT_STORE, getFromStorage, ProjectContext, setToStorage, useProject } from "./project";
import EditorApp from "./EditorApp";

import { runInAction, set } from "mobx";
import { useSession, signIn, SessionProvider } from "./auth/react";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogBody,
  Divider,
  FormGroup,
  InputGroup,
  OverlayToaster,
  Spinner,
} from "@blueprintjs/core";
import { FcGoogle } from "@meronex/icons/fc";
import { FaEnvelope } from "@meronex/icons/fa";

import SlotCounter from "react-slot-counter";
import { createRoot } from "react-dom/client";

import * as Frigade from '@frigade/react';
import { QueryClient, QueryClientProvider, useQueryClient } from "@tanstack/react-query";
import { ProjectPricingTableDialog } from "./components/PricingTableDialog";
import { observer } from "mobx-react-lite";
import { createReferral, getBillingPortalSession } from "./api";
import { Z_INDEX } from "./zIndexes";

import { BasicModeSection } from "./sections/basic-mode";

import fr from "./translations/fr";
import en from "./translations/en";
import id from "./translations/id";
import ru from "./translations/ru";
import ptBr from "./translations/pt-br";

import CrispChat from "./crisp";
import ReferralDialog from "./components/ReferralDialog";

import posthog from "posthog-js";

import type { FormFieldProps } from "@frigade/react";

// load default translations
setTranslations(en);

// Constants
const t0 = 1720652400000;
const t1 = 1720677600000;
const delta_t = t1 - t0;

// Adjustability parameters
const base_value = 303; // Base value of the function
const scaling_factor = 250; // Scaling factor for overall output scale
const cos_factor = 0.5; // Coefficient for cosine term
const sin_factor = 0.2; // Coefficient for sine term
const cos_pi_factor = 0.1; // Coefficient for cosine of pi term

const stop_time = 1720677600000;
const resume_time = 1720710000000;

// Function to compute f(t) with adjustable scaling and variability
export function f(t: number) {
  if (stop_time < t) {
    if (t < resume_time) {
      t = stop_time;
    } else {
      t -= resume_time - stop_time;
    }
  }

  const u = (t - t0) / delta_t;
  // Adjust the scaling and variability using parameters
  const value =
    base_value +
    scaling_factor *
      (u +
        cos_factor * (1 - Math.cos((Math.PI / 2) * u)) +
        sin_factor * Math.sin(((2 * Math.PI) / 3) * u) -
        cos_pi_factor * Math.cos(Math.PI * u));
  return Math.floor(value);
}

const useToaster = () => {
  const [userCount, setUserCount] = useState(0);
  const [proxyUserCount, setProxyUserCount] = useState(0);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (getFromStorage("polotno-new-user-toasts-disabled")) {
      setDisabled(true);
    }
  }, []);

  useEffect(() => {
    if (disabled) return;
    const t = setInterval(() => {
      const newVal = f(Date.now());
      setUserCount(newVal);
      if (newVal != proxyUserCount) {
        setProxyUserCount(newVal);
      }
    }, 3000);

    return () => clearInterval(t);
  }, [proxyUserCount]);

  useEffect(() => {
    if (proxyUserCount && !disabled) {
      AppToaster.then((toaster) =>
        toaster.show({
          message:
            "Another teacher just made their first resource on TeachShare!",
          onDismiss(didTimeoutExpire) {
            if (!didTimeoutExpire) {
              setDisabled(true);
              setToStorage("polotno-new-user-toasts-disabled", "disabled");
            }
          },
        })
      );
    }
  }, [proxyUserCount]);
}

const SignInDialog = observer(() => {
  const project = useProject();

  const [emailLoading, setEmailLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  return (
      <Dialog
        isOpen={project.loginDialogOpen}
        onClose={() => project.closeLoginDialog()}
        className="w-[unset] p-8"
      >
        <div className="flex max-h-full w-full max-w-[32rem]">
          <div className="flex flex-col gap-3 text-center sm:text-left">
            <h2 className="pb-2 text-xl font-semibold leading-none tracking-tight text-primary text-center">
              Welcome to the TeachShare Creator!
            </h2>
            <p className="text-md text-center">
              We're excited to launch our <strong>public beta</strong>!
            </p>
            <p className="text-md text-center">
              Easily <strong>import</strong> or <strong>create</strong>{" "}
              educational resources, <strong>customize</strong> their topics,{" "}
              and <strong>align</strong> the content with curriculum standards{" "}
              or grade levels on our user-friendly canvas.
            </p>
            <Button
              intent="primary"
              onClick={async () => {
                await project.deprecateDesign();
                await signIn("google");
              }}
              className="mx-16"
            >
              <FcGoogle className="inline-block" /> Continue with Google
            </Button>
            <Divider className="mx-16" />
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                const formData = new FormData(e.target as HTMLFormElement);
                const email = formData.get("email") as string;
                setEmailLoading(true);
                setEmailSent(false);
                await project.deprecateDesign();
                await signIn("email", { email, redirect: false });
                setEmailLoading(false);
                setEmailSent(true);
              }}
              className="flex flex-col mx-16"
            >
              <FormGroup
                // helperText="Helper text with details..."
                label="Email"
                labelFor="email-signup-input"
              // labelInfo="(required)"
              >
                <InputGroup
                  id="email-signup-input"
                  placeholder="example@domain.com"
                  name="email"
                  required
                />
              </FormGroup>
              <p className="text-center text-xs">
                We{"'"}ll send you a link for password-less sign-on!
              </p>
              <Button
                intent="primary"
                type="submit"
                loading={emailLoading}
              >
                <FaEnvelope className="inline-block" /> Continue with Email
              </Button>
              {emailSent && (
                <p className="text-center text-sm">
                  Check your email for a link to sign in. If you don{"'"}t see it
                  in your inbox, check your spam folder.
                </p>
              )}
            </form>
            <div className="flex flex-col gap-4">
              <p className="mt-4 mb-0 text-center text-xs">
                By continuing, you agree to our{" "}
                <a href="https://docs.google.com/document/d/e/2PACX-1vSBHEI0O-gHEJJpv5M-c1lZmSmKWwdqPHWR8kRiUh_OTPxYkm0Ihsj22kf0P_bblw/pub" target="_blank">
                  Terms of Service
                </a>{" "}
                and{" "}
                <a href="https://www.worksheets.ai/privacy" target="_blank">
                  Privacy Policy
                </a>
                , and confirm you are 18 years of age or older.
              </p>
              <p className="mb-0 text-center text-xs">
                Any questions? Reach out at{" "}
                <a href="mailto:aryan@teachertool.ai">
                  aryan@teachertool.ai
                </a>!
              </p>
            </div>
          </div>
        </div>
      </Dialog>
  )
});

const DEMO_MODE_LINK = "https://www.teachshare.com/creator";

const DemoDialog = observer(() => {
  const project = useProject();

  return (
      <Dialog
        isOpen={project.demoDialogOpen}
        onClose={() => project.closeDemoDialog()}
        className="w-[unset] p-8"
      >
        <div className="flex max-h-full w-full max-w-[32rem]">
          <form className="flex flex-col gap-3 text-center sm:text-left" action={DEMO_MODE_LINK}>
            <h2 className="pb-2 text-xl font-semibold leading-none tracking-tight text-primary text-center">
              Glad You're Enjoying the TeachShare Creator!
            </h2>
            <p className="text-md text-center">
              To see more, visit our full editor at:
            </p>
            <p className="text-md text-center">
              <a href={DEMO_MODE_LINK}>https://www.teachshare.com/creator</a>
            </p>
            <Button
              intent="primary"
              type="submit"
              className="mx-16"
            >
              Take Me There!
            </Button>
          </form>
        </div>
      </Dialog>
  )
});

const SignedOutApp = () => {
  // unstable_setAnimationsEnabled(true);

  const store = createStore({
    key: import.meta.env.VITE_PUBLIC_POLOTNO_KEY!,
    showCredit: false,
  });
  store.addPage();
  // window.store = store;

  const project = createProject({ store });
  // window.project = project;

  return (
    <>
      <ProjectContext.Provider value={project}>
        <EditorApp store={store} />
        <ProjectPricingTableDialog />
      </ProjectContext.Provider>
    </>
  );
};

const SURVEY_FORM_EMAIL_KEYS = ["email0", "email1", "email2"];

const SurveyForm = () => {
  const queryClient = useQueryClient();

  return (
    <Frigade.Form
      flowId="flow_DLuq3TwP"
      dismissible={false}
      modal
      as={Frigade.Dialog}
      zIndex={Z_INDEX("cancelSurvey")}
      onPrimary={async (_step, _event, properties) => {
        if (!properties) return;

        for (const key of SURVEY_FORM_EMAIL_KEYS) {
          const email = properties[key];
          if (email && typeof email === "string") {
            try {
              await createReferral({ email });
            } catch (e) {
              console.warn("Failed to create referral for", email, e);
            }
          }
        }
        queryClient.invalidateQueries({ queryKey: ['referrals'] });
        return true;
      }}
    />
  )
}

const CancelForm = ({ close }: { close: () => void }) =>
  <Frigade.Form
    flowId={import.meta.env.VITE_PUBLIC_FRIGADE_CANCEL_SURVEY_ID!}
    dismissible
    forceMount
    modal
    as={Frigade.Dialog}
    onPrimary={async () => {
      const { session: { url } } = await getBillingPortalSession("subscription_cancel");
      window.open(url, '_blank');
      close();
    }}
    onSecondary={close}
    onDismiss={close}
    zIndex={Z_INDEX("cancelSurvey")}
  />

const ProjectHandler = observer(({
  project,
  session,
}: {
  project: ReturnType<typeof createProject>,
  session: ReturnType<typeof useSession>,
}) => {
  useEffect(() => {
    runInAction(() => {
      project.cloudEnabled = session.status === "authenticated";
    })
  }, [project, session.status]);

  useEffect(() => {
    project.setSubscriptionActive(session.status === "authenticated" && session.data.user.subscriptionActive);
  }, [project, session]);

  const oldSession = useRef(session);

  useEffect(() => {
    if (session.status === "authenticated") {
      const { id, ...restUser } = session.data.user;
      posthog.identify(session.data.user.id, restUser);
    } else if (oldSession.current.status === "authenticated" && session.status === "unauthenticated") {
      posthog.reset();
    }
    oldSession.current = session;
  }, [session]);

  // console.log("Rendering SubApp", {session, store, project});

  const params = new URLSearchParams(location.search);
  const key = params.get("key");
  const ref = params.get("ref");

  const runKeyEffectOnceRef = useRef(false);

  useEffect(() => {
    if (session.status === "loading") return;
    if (runKeyEffectOnceRef.current) return;

    if (session.status === "authenticated" && key) {
      runKeyEffectOnceRef.current = true;
      const href = new URL(window.location.href)
      const url = new URL(href.pathname, href.origin).toString();
      const newParams = new URLSearchParams(Array.from(params.entries()).filter(([key, val]) => key !== "key"));
      const newUrl = newParams.size ? url + "?" + newParams.toString() : url;
      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, "", newUrl);

      setTimeout(() => {
        if (project.basicMode) {
          project.store.openSidePanel(BasicModeSection.name);
        } else {
          project.store.openSidePanel("");
        }
        project.loadById(key);
      }, 100);
    }
  }, [session, key])

  const fromWorksheetsAIToastKey = useRef<string | null>(null);
  const runRefEffectOnceRef = useRef(false);

  useEffect(() => {
    if (session.status === "loading") return;
    if (runRefEffectOnceRef.current) return;
    runRefEffectOnceRef.current = true;

    if (session.status === "unauthenticated" && ref === "worksheetsai") {
      const href = new URL(window.location.href)
      const url = new URL(href.pathname, href.origin).toString();
      const newParams = new URLSearchParams(Array.from(params.entries()).filter(([key, val]) => key !== "ref"));
      const newUrl = newParams.size ? url + "?" + newParams.toString() : url;
      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, "", newUrl);

      AppToaster.then((toaster) =>
        toaster.show({
          message: <>
            WorksheetsAI is now part of TeachShare! Your account has been
            migrated.  Liked the old interface better? Visit{" "}
            <a href="https://legacy.worksheets.ai">legacy.worksheets.ai</a>.
          </>,
          intent: "primary",
          timeout: -1,
        })
      ).then((key) => {
        fromWorksheetsAIToastKey.current = key;
      });
    }
  }, [session, key]);

  useEffect(() => {
    const key = fromWorksheetsAIToastKey.current;
    if (session.status === "authenticated" && key) {
      fromWorksheetsAIToastKey.current = null;
      AppToaster.then((toaster) => toaster.dismiss(key));
    }
  }, [session.status]);

  useToaster();

  useEffect(() => {
    if (session.status === "unauthenticated" && !project.demoMode) {
      project.openLoginDialog();
    }
    if (session.status === "authenticated") {
      project.closeLoginDialog();
    }
    project.fetchDemoWorksheetsLeft();
  }, [session.status]);

  useEffect(() => {
    if (project.language.startsWith("fr")) {
      setTranslations(fr, { validate: true });
    } else if (project.language.startsWith("id")) {
      setTranslations(id, { validate: true });
    } else if (project.language.startsWith("ru")) {
      setTranslations(ru, { validate: true });
    } else if (project.language.startsWith("pt")) {
      setTranslations(ptBr, { validate: true });
    } else {
      setTranslations(en, { validate: true });
    }
  }, [project.language]);

  // useEffect(() => {
  //   project.firstLoad();
  // }, []);

  return (<>
        <Frigade.Provider
          apiKey={import.meta.env.VITE_PUBLIC_FRIGADE_API_KEY!}
          userId={session?.data?.user?.id}
          userProperties={{ email: session?.data?.user?.email || undefined }}
        >
          {project.enableTour && !project.basicMode && (
            <Frigade.Tour flowId={import.meta.env.VITE_PUBLIC_FRIGADE_TOUR_DEFAULT_ID!} zIndex={Z_INDEX("tour")} />
          )}
          {project.cancelSurveyOpen && (
            <CancelForm close={() => project.closeCancelSurvey()} />
          )}
          {session.status === "authenticated" && (
            <SurveyForm />
          )}
        </Frigade.Provider>
          <SignInDialog />
          <ReferralDialog />
          <ProjectPricingTableDialog />
          <DemoDialog />
          {(session.status === "loading" || project.loading || project.status === "loading") && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0,0,0,0.5)",
                zIndex: Z_INDEX("loading"),
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                }}
              >
                <Spinner />
              </div>
            </div>
          )}
    </>
  );
});


const SubApp = observer(({
  project,
  store,
}: {
  project: ReturnType<typeof createProject>;
  store: StoreType;
}) => {
  const session = useSession();

  return (<>
      <ProjectContext.Provider value={project}>
          <EditorApp store={store} />
        <ProjectHandler project={project} session={session} />
      </ProjectContext.Provider>
  </>)
});

export const AppToaster = OverlayToaster.createAsync(
  {
    position: "top",
  },
  {
    // Use createRoot() instead of ReactDOM.render(). This can be deleted after
    // a future Blueprint version uses createRoot() for Toasters by default.
    domRenderer: (toaster, containerElement) =>
      createRoot(containerElement).render(toaster),
  }
);

const queryClient = new QueryClient();

const App = () => {
  const params = new URLSearchParams(location.search);
  const demoMode = !!params.get("demo") || !!params.get("key");
  const enableTour = params.get("tour") !== "false";
  const enableInteractive = params.get("interactive") === "true";

  const store = createStore({
    key: import.meta.env.VITE_PUBLIC_POLOTNO_KEY!,
    showCredit: false,
  });
  store.addPage();

  const project = createProject({ store, demoMode, enableTour, flags: enableInteractive ? ["interactive-export"] : [] });

  useEffect(() => {
    project.firstLoad();
  }, []);

  useEffect(() => {
    window.store = store;
  }, [store]);

  useEffect(() => {
    window.project = project;
  }, [project]);

  // useEffect(() => {
  //   const t = setInterval(() => {
  //     setUserCount(f(Date.now()));
  //   }, 3000);

  //   return () => clearInterval(t);
  // });

  // console.log("Rendering App", {store, project});

  return (
    <SessionProvider baseUrl={import.meta.env.VITE_PUBLIC_NEXTAUTH_URL}>
      <QueryClientProvider client={queryClient}>
        <SubApp
          store={store}
          project={project}
        />
      </QueryClientProvider>
      <CrispChat />
    </SessionProvider>
  );
};

export default App;
