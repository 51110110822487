import React, { createContext, useRef } from "react";
import { observer } from "mobx-react-lite";
import {
  Dialog,
  Navbar,
  NavbarHeading,
  OverlaysProvider,
  Icon as BlueprintIcon,
} from "@blueprintjs/core";

import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import {
  SidePanel,
  DEFAULT_SECTIONS,
  TemplatesSection,
  TextSection,
  PhotosSection,
  ElementsSection,
  // UploadSection,
  BackgroundSection,
  PagesSection,
  LayersSection,
  SizeSection,
  VideosSection,
  SectionTab,
} from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";
import { Tooltip } from "polotno/canvas/tooltip";
import { PagesTimeline } from "polotno/pages-timeline";
import { setTranslations, unstable_useHtmlTextRender } from "polotno/config";
import { unstable_registerToolbarComponent } from "polotno/config";
import { MOBILE_BREAKPOINT } from "polotno/utils/screen";

import { loadFile } from "./file";

import { QrSection } from "./sections/qr-section";
import { QuotesSection } from "./sections/quotes-section";
import { IconsSection } from "./sections/icons-section";
import { ShapesSection } from "./sections/shapes-section";
import { StableDiffusionSection } from "./sections/stable-diffusion-section";
import { MyDesignsSection } from "./sections/my-designs-section";

import { AIWriteMenu } from "./ai-text";
// import { VideosSection } from './sections/video-section';
// import { UploadSection } from './sections/upload-section';

import { type ProjectType, useProject } from "./project";

import { ImageRemoveBackground } from "./background-remover";

import Topbar from "./topbar/topbar";
import { type StoreType } from "polotno/model/store";
import { AIMagicMenu, AIMagicTextTypesMenu } from "./ai-magic";
import { UploadSection } from "./sections/upload-section";
import { LegacyTemplatesSection } from "./sections/legacy-templates";
import { BasicModeSection } from "./sections/basic-mode";
import { ActionControls } from "./topbar/action-controls";
import { MainSection } from "./sections/main-section";
import {
  BackwardItem,
  Camera,
  DocumentUpload,
  Icon,
  Layer,
  Maximize3,
  PictureFrame,
  ScanBarcode,
  Shapes,
  TextBlock,
} from "iconsax-react";
import { createPortal } from "react-dom";

unstable_useHtmlTextRender(true);

import { quillRef } from "polotno/canvas/html-element";

// @ts-ignore
window.quillRef = quillRef;
// window.__polotnoQuill

import Quill from "quill";
import { QuestionsAnswersSection } from "./sections/questions-answers";
import type ParchmentImport from "parchment";
import BlockBlot from "parchment/dist/src/blot/block";
import ContainerBlot from "parchment/dist/src/blot/abstract/container";
import InlineBlot from "parchment/dist/src/blot/inline";
import EmbedBlot from "parchment/dist/src/blot/embed";
import CustomHistory from "./topbar/custom-history";

// import * as mobx from "mobx";

// mobx.reaction(
//   () => quillRef.currentFormat,
//   (format) => {
//     console.log("format changed", JSON.stringify(format));
//   }
// );

// @ts-ignore
window.Quill = Quill;

// window.__polotnoQuill

const Parchment = Quill.import("parchment") as typeof ParchmentImport;
const BlockEmbed = Quill.import("blots/block/embed") as typeof EmbedBlot;
const Block = Quill.import("blots/block") as typeof BlockBlot;
const Container = Quill.import("blots/container") as typeof ContainerBlot;
const Inline = Quill.import("blots/inline") as typeof InlineBlot;

class Hr extends BlockEmbed {
  static blotName = "hr";
  static tagName = "hr";
}

Quill.register(Hr);

class HiddenSeparator extends BlockEmbed {
  static blotName = "hiddenseparator";
  static tagName = "div";
  static className = "hiddenseparator";
}

Quill.register(HiddenSeparator);

class GenericDataBlockBlot extends Block {
  static blotName = "genericdatablock";
  static tagName = "div";
  static className = "genericdatablock";

  static create(dataset: Record<string, string>) {
    const node = super.create(undefined) as HTMLElement;
    for (const key in dataset) {
      if (dataset[key]) {
        node.dataset[key] = dataset[key];
      }
    }
    return node;
  }

  static value(domNode: HTMLElement) {
    return {
      ...domNode.dataset,
    };
  }

  static formats(node: HTMLElement) {
    return {
      ...node.dataset,
    };
  }

  format(name: string, value: string) {
    if (value) {
      this.domNode.dataset[name] = value;
    } else {
      delete this.domNode.dataset[name];
    }
  }
}

Quill.register(GenericDataBlockBlot);

class GenericDataInlineBlot extends Inline {
  static blotName = "genericdatainline";
  static tagName = "span";
  static className = "genericdatainline";

  static create(dataset: Record<string, string>) {
    const node = super.create(undefined) as HTMLElement;
    for (const key in dataset) {
      if (dataset[key]) {
        node.dataset[key] = dataset[key];
      }
    }
    return node;
  }

  static value(domNode: HTMLElement) {
    return {
      ...domNode.dataset,
    };
  }

  static formats(node: HTMLElement) {
    return {
      ...node.dataset,
    };
  }

  format(name: string, value: string) {
    if (value) {
      this.domNode.dataset[name] = value;
    } else {
      delete this.domNode.dataset[name];
    }
  }
}

Quill.register(GenericDataInlineBlot);

class InlineQuestionBlot extends Inline {
  static blotName = "question";
  static tagName = "span";
  static className = "question";

  static formats() {
    return true;
  }
}

Quill.register(InlineQuestionBlot);

const InlineQuestionBlotAttributor = new Parchment.Attributor.Class(
  InlineQuestionBlot.blotName,
  InlineQuestionBlot.className,
  {
    scope: Parchment.Scope.INLINE,
  }
);

Quill.register(InlineQuestionBlotAttributor);

class InlineAnswerBlot extends Inline {
  static blotName = "answer";
  static tagName = "span";
  static className = "answer";

  static formats() {
    return true;
  }
}

Quill.register(InlineAnswerBlot);

const InlineAnswerBlotAttributor = new Parchment.Attributor.Class(
  InlineAnswerBlot.blotName,
  InlineAnswerBlot.className,
  {
    scope: Parchment.Scope.INLINE,
  }
);

Quill.register(InlineAnswerBlotAttributor);

// WrapperBlot class (similar to BaseContainerBlot)
class WrapperBlot extends Container {
  static scope = Parchment.Scope.BLOCK_BLOT;

  optimize(context) {
    super.optimize(context);
    let next = this.next;
    if (
      next != null &&
      next.prev === this &&
      next.statics.blotName === this.statics.blotName &&
      next.domNode.tagName === this.domNode.tagName
    ) {
      next.moveChildren(this);
      next.remove();
    }
  }

  replace(target) {
    if (target.statics.blotName !== this.statics.blotName) {
      let item = Parchment.create(this.statics.defaultChild);
      target.moveChildren(item);
      this.appendChild(item);
    }
    super.replace(target);
  }

  // static formats (node: HTMLElement) {
  //     return {
  //       ...node.dataset,
  //     }
  // }

  // static value (node: HTMLElement) {
  //     return {
  //       ...node.dataset,
  //     }
  //   }

  // format (name: string, value: string) {
  //   if (value) {
  //     this.domNode.dataset[name] = value;
  //   } else {
  //     delete this.domNode.dataset[name];
  //   }
  //   this.children.forEach((child) => {
  //     child.format(name, value);
  //   });
  // }
}

export class WrappedBlot extends Block {
  static deleteWhenEmpty = false;

  static create(dataset: Record<string, string>) {
    const node = super.create(undefined) as HTMLElement;
    const dataValuesWhiteList =
      (this as unknown as { dataValuesWhiteList: string[] })
        .dataValuesWhiteList || [];
    for (const key in dataset) {
      if (dataValuesWhiteList.includes(key)) {
        node.dataset[key] = dataset[key];
      }
    }
    return node;
  }

  static value(domNode: HTMLElement) {
    return {
      ...domNode.dataset,
    };
  }

  attach() {
    super.attach();
    const possibleParentNames = Array.isArray(this.statics.parentName)
      ? this.statics.parentName
      : [this.statics.parentName];
    if (!possibleParentNames.includes(this.parent.statics.blotName)) {
      const wrapper = Parchment.create(this.statics.parentName);
      this.wrap(wrapper);
    }
  }

  static formats(domNode: HTMLElement) {
    const classMatch =
      this.className && domNode.classList.contains(this.className);
    const tagMatch =
      domNode.tagName.toLowerCase() === this.tagName.toLowerCase();

    // return this.className ? classMatch && tagMatch : tagMatch;

    return {
      [this.blotName]: this.className ? classMatch && tagMatch : tagMatch,
      ...domNode.dataset,
    };
  }

  format(name: string, value: string) {
    const dataValuesWhiteList = this.statics.dataValuesWhiteList || [];
    if (dataValuesWhiteList.includes(name)) {
      if (value) {
        this.domNode.dataset[name] = value;
      } else {
        delete this.domNode.dataset[name];
      }
      const parentChildren = Array.from(
        this.domNode.parentElement?.children || []
      );
      const siblings = parentChildren.filter((child) => child !== this.domNode);
      for (const sibling of siblings) {
        if (sibling instanceof HTMLElement) {
          if (value) {
            sibling.dataset[name] = value;
          } else {
            delete sibling.dataset[name];
          }
        }
      }
    } else {
      super.format(name, value);
    }
  }

  remove() {
    if (this.prev == null && this.next == null) {
      this.parent.remove();
    } else {
      super.remove();
    }
  }

  removeChild(child) {
    super.removeChild(child);
    if (
      this.statics.deleteWhenEmpty &&
      child.prev == null &&
      child.next == null
    ) {
      this.remove();
    }
  }

  replaceWith(name, value) {
    this.parent.isolate(this.offset(this.parent), this.length());
    if (name === this.parent.statics.blotName) {
      this.parent.replaceWith(name, value);
      return this;
    } else {
      this.parent.unwrap();
      super.replaceWith(name, value);
    }
  }
}

// QuestionContainerBlot (similar to PullquoteContainer)
export class QuestionContainerBlot extends WrapperBlot {
  static blotName = "questioncontainer";
  static tagName = "div";
  static className = "questioncontainer";
  static defaultChild = "questioncontainer-para";
}

// QuestionParagraphBlot (similar to PullquotePara)
export class QuestionParagraphBlot extends WrappedBlot {
  static blotName = "questioncontainer-para";
  static className = "questioncontainer-para";
  static tagName = "p";
  static parentName = "questioncontainer";
  static dataValuesWhiteList = ["correctanswer", "type"];
}

QuestionContainerBlot.allowedChildren = [QuestionParagraphBlot];
QuestionParagraphBlot.requiredContainer = QuestionContainerBlot;

// Register the blots
Quill.register(QuestionContainerBlot);
Quill.register(QuestionParagraphBlot);

// Function to wrap selected content in a container (modified)
function wrapInContainer(quill: Quill, containerType: string) {
  const range = quill.getSelection();
  if (range) {
    const [line, offset] = quill.getLine(range.index);
    const lineIndex = quill.getIndex(line);
    const lineLength = line.length();

    const startIndex = lineIndex;
    const endIndex =
      range.length === 0
        ? lineIndex + lineLength
        : quill.getIndex(quill.getLine(range.index + range.length)[0]) +
          quill.getLine(range.index + range.length)[0].length();

    const formats = quill.getFormat(startIndex, endIndex - startIndex);
    if (formats[containerType]) {
      quill.removeFormat(startIndex, endIndex - startIndex);
    } else {
      quill.formatLine(startIndex, endIndex - startIndex, containerType, true);
    }
  }
}

window.wrapInContainer = wrapInContainer;

// The toggleContainerLines function can remain as is

// ... (rest of your existing code)

// ToggleButton = (0, mobx_react_lite_1.observer)(({ active: e, globalActive: t, format: l, element: o, disableGlobal: r, enableGlobal: n, icon: i }) =>
//   react_1.default.createElement(core_1.Button, {
//     minimal: !0,
//     icon: i,
//     active: e,
//     onMouseDown: e => {
//       e.preventDefault();
//     },
//     onClick: e => {
//       let i = window.__polotnoQuill;
//       if (i) {
//         const e = i.getSelection();
//         return i.formatText(e.index, e.length, l, !html_element_1.quillRef.currentFormat[l], "user"), void (t && r());
//       }
//       i = createTempQuill({ html: o.text });
//       i.setSelection(0, i.getLength(), "api");
//       i.format(l, !1);
//       const a = i.root.innerHTML;
//       removeTempQuill(i);
//       o.set({ text: a });
//       t ? r() : n();
//     }
//   })
// );

// const ToggleButton = observer(({
//   active,
//   globalActive,
//   format,
//   element,
//   disableGlobal,
//   enableGlobal,
//   icon
// }) => (
//   <Button
//     minimal
//     icon={icon}
//     active={active}
//     onMouseDown={(e) => {
//       e.preventDefault();
//     }}
//     onClick={(e) => {
//       let quill = window.__polotnoQuill;
//       if (quill) {
//         const selection = quill.getSelection();
//         quill.formatText(
//           selection.index,
//           selection.length,
//           format,
//           !quillRef.currentFormat[format],
//           "user"
//         );
//         if (globalActive) {
//           disableGlobal();
//         }
//         return;
//       }
//       quill = createTempQuill({ html: element.text });
//       quill.setSelection(0, quill.getLength(), "api");
//       quill.format(format, false);
//       const newHtml = quill.root.innerHTML;
//       removeTempQuill(quill);
//       element.set({ text: newHtml });
//       globalActive ? disableGlobal() : enableGlobal();
//     }}
//   />
// ));

const sections = (project: ProjectType) => (
  project.basicMode
  ? [BasicModeSection]
  : [
    MainSection,
    MyDesignsSection,
    // {
    //   ...TemplatesSection,
    //   Tab: (props) => (
    //     <SectionTab name="Templates" {...props}>
    //       <BackwardItem variant="Linear" className="inline" />
    //     </SectionTab>
    //   ),
    // },
    LegacyTemplatesSection,
    {
      ...TextSection,
      Tab: (props) => (
        <SectionTab name="Text" {...props}>
          <TextBlock variant="Linear" className="inline" />
        </SectionTab>
      ),
    },
    {
      ...ShapesSection,
      Tab: (props) => (
        <SectionTab name="Shapes" {...props}>
          <Shapes variant="Linear" className="inline" />
        </SectionTab>
      ),
    },
    {
      ...IconsSection,
      Tab: (props) => (
        <SectionTab name="Icons" {...props}>
          <Icon variant="Linear" className="inline" />
        </SectionTab>
      ),
    },
    StableDiffusionSection,
    {
      ...PhotosSection,
      Tab: (props) => (
        <SectionTab name="Photos" {...props}>
          <Camera variant="Linear" className="inline" />
        </SectionTab>
      ),
    },
    {
      ...UploadSection,
      Tab: (props) => (
        <SectionTab name="Upload" {...props}>
          <DocumentUpload variant="Linear" className="inline" />
        </SectionTab>
      ),
    },
    // ElementsSection,
    {
      ...BackgroundSection,
      Tab: (props) => (
        <SectionTab name="Backgrounds" {...props}>
          <PictureFrame variant="Linear" className="inline" />
        </SectionTab>
      ),
    },
    // PagesSection,
    {
      ...LayersSection,
      Tab: (props) => (
        <SectionTab name="Layers" {...props}>
          <Layer variant="Linear" className="inline" />
        </SectionTab>
      ),
    },
    {
      ...SizeSection,
      Tab: (props) => (
        <SectionTab name="Size" {...props}>
          <Maximize3 variant="Linear" className="inline" />
        </SectionTab>
      ),
    },
    // VideosSection,
    // QuotesSection,
    QrSection,
    ...(project.flags.includes("interactive-export") ? [QuestionsAnswersSection] : []),
  ]
);

/*
// replace elements section with just shapes
DEFAULT_SECTIONS.splice(3, 1, ShapesSection);
// add icons
DEFAULT_SECTIONS.splice(3, 0, IconsSection);
// add two more sections
DEFAULT_SECTIONS.push(QuotesSection, QrSection);
// DEFAULT_SECTIONS.unshift(UploadSection);
DEFAULT_SECTIONS.unshift(MyDesignsSection);

DEFAULT_SECTIONS.push(StableDiffusionSection);
// DEFAULT_SECTIONS.push(VideosSection);
*/

const isStandalone = () => {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone
  );
};

const getOffsetHeight = () => {
  let safeAreaInsetBottom = 0;

  if (isStandalone()) {
    // Try to get the safe area inset using env() variables
    const safeAreaInsetBottomString = getComputedStyle(
      document.documentElement
    ).getPropertyValue("env(safe-area-inset-bottom)");
    if (safeAreaInsetBottomString) {
      safeAreaInsetBottom = parseFloat(safeAreaInsetBottomString);
    }

    // Fallback values for specific devices if env() is not supported
    if (!safeAreaInsetBottom) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/iPhone|iPad|iPod/i.test(userAgent) && !window.MSStream) {
        // This is an approximation; you might need to adjust this value based on testing
        safeAreaInsetBottom = 20; // Example fallback value for iPhone
      }
    }
  }

  return window.innerHeight - safeAreaInsetBottom;
};

const useHeightAndWidth = () => {
  const [size, setSize] = React.useState([
    getOffsetHeight(),
    window.innerWidth,
  ] as const);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setSize([getOffsetHeight(), window.innerWidth]);
    });
  }, []);
  return size;
};

const EditorApp = observer(({ store }: { store: StoreType }) => {
  const project = useProject();
  const [height, width] = useHeightAndWidth();

  const [overrideMobile, setOverrideMobile] = React.useState(false);

  const toolbarContainerRef = useRef<HTMLDivElement>(null);

  const actualToolbar =
    toolbarContainerRef.current?.firstElementChild?.firstElementChild ?? null;

  const handleDrop = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    // skip the case if we dropped DOM element from side panel
    // in that case Safari will have more data in "items"
    if (ev.dataTransfer.files.length !== ev.dataTransfer.items.length) {
      return;
    }
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      loadFile(ev.dataTransfer.files[i], store);
    }
  };

  React.useEffect(() => {
    store.selectElements([]);
    if (project.basicMode) {
      store.openSidePanel(BasicModeSection.name);
      project.setRemixSideBarOpen(false);
      document.body.classList.add("basic-mode");
    } else {
      store.openSidePanel(LegacyTemplatesSection.name);
      project.setRemixSideBarOpen(true);
      document.body.classList.remove("basic-mode");
    }
  }, [project.basicMode]);

  const nullComponent = () => null;

  return (
    <div
      style={{
        width: "100vw",
        height: height + "px",
        display: "flex",
        flexDirection: "column",
      }}
      onDrop={handleDrop}
    >
      <Dialog
        isOpen={!overrideMobile && width < MOBILE_BREAKPOINT}
        onClose={() => setOverrideMobile(true)}
        canOutsideClickClose={false}
        className="w-[unset] p-8 relative force-overlay-top"
      >
        <BlueprintIcon
          icon="cross"
          size={24}
          role="button"
          aria-label="Close"
          className="absolute w-6 h-6 top-4 right-4 cursor-pointer"
          onClick={() => setOverrideMobile(true)}
        />
        <div className="flex max-h-full w-full max-w-[32rem]">
          <div className="flex flex-col gap-3 text-center sm:text-left">
            <h2 className="pb-2 text-xl font-semibold leading-none tracking-tight text-primary text-center">
              Welcome to the TeachShare Creator!
            </h2>
            <p className="text-md text-center">
              We're excited you're here! The TeachShare Creator works best on a
              desktop or laptop computer. Head to{" "}
              <a href="https://teachshare.com/creator">
                https://teachshare.com/creator
              </a>{" "}
              on your laptop or desktop and experience the full power of the
              TeachShare Creator!
            </p>
            <p className="text-md text-center">
              (Stay tuned, mobile support is coming soon!)
            </p>
          </div>
        </div>
      </Dialog>
      {actualToolbar &&
        createPortal(
          <>
            <Navbar.Group align={"left"} className="justify-center order-1">
              <NavbarHeading
                style={{
                  paddingLeft: "8px",
                  color: "#485AEE",
                  fontSize: 24, //18,
                  fontFamily: "Outfit",
                  fontWeight: "600",
                  letterSpacing: 0.18,
                  wordWrap: "break-word",
                }}
              >
                TeachShare
              </NavbarHeading>
            </Navbar.Group>
            <div className="bp5-navbar-group flex-1 order-2"></div>
          </>,
          actualToolbar
        )}
      <OverlaysProvider>
        <PolotnoContainer className="polotno-app-container">
          <SidePanelWrap>
            <SidePanel
              store={store}
              sections={ sections(project)}
              defaultSection={
                project.basicMode
                  ? BasicModeSection.name
                  : LegacyTemplatesSection.name
              }
            />
          </SidePanelWrap>
          <WorkspaceWrap>
            <div
              className="polotno-editor-bar-container"
              ref={toolbarContainerRef}
            >
              <Toolbar
                store={store}
                components={{
                  ...(project.basicMode
                    ? {
                        TextFontFamily: nullComponent,
                        TextFontSize: nullComponent,
                        TextFontVariant: nullComponent,
                        TextFilters: nullComponent,
                        TextFill: nullComponent,
                        TextSpacing: nullComponent,
                        TextAnimations: nullComponent,
                        // TextAiWrite: nullComponent,

                        ImageFlip: nullComponent,
                        ImageFilters: nullComponent,
                        ImageFitToBackground: nullComponent,
                        ImageCrop: nullComponent,
                        ImageClip: nullComponent,
                        ImageRemoveBackground: nullComponent,
                        ImageAnimations: nullComponent,

                        SvgFlip: nullComponent,
                        SvgFilters: nullComponent,
                        SvgColors: nullComponent,
                        SvgAnimations: nullComponent,

                        LineSettings: nullComponent,
                        LineColor: nullComponent,
                        LineHeads: nullComponent,
                        LineAnimations: nullComponent,

                        FigureFill: nullComponent,
                        FigureStroke: nullComponent,
                        FigureSettings: nullComponent,
                        FigureFilters: nullComponent,
                        FigureAnimations: nullComponent,

                        VideoTrim: nullComponent,
                        VideoAnimations: nullComponent,

                        ManyAnimations: nullComponent,

                        PageDuration: nullComponent,

                        History: CustomHistory,
                        Group: nullComponent,
                        Position: nullComponent,
                        Opacity: nullComponent,
                        Lock: nullComponent,
                        Duplicate: nullComponent,
                        Remove: nullComponent,
                      }
                    : {
                        ImageRemoveBackground,
                        // TextAIWrite: AIWriteMenu,
                        TextAiMagicMenu: AIMagicMenu,
                        ImageAiMagicMenu: AIMagicMenu,
                        ManyAiMagicMenu: AIMagicMenu,
                        TextAIMagicTextTypesMenu: AIMagicTextTypesMenu,
                        ImageAIMagicTextTypesMenu: AIMagicTextTypesMenu,
                        ManyAIMagicTextTypesMenu: AIMagicTextTypesMenu,
                        // FIXME: Group[...] doesn't seem to work
                        // GroupAIMagicTextTypesMenu: AIMagicTextTypesMenu,
                        History: CustomHistory,
                      }),
                  ActionControls,
                }}
              />
            </div>
            <Workspace
              store={store}
              components={{
                Tooltip,
                TextAIWrite: AIWriteMenu,
                ...(project.basicMode
                  ? {
                      PageControls: nullComponent,
                    }
                  : {}),
              }}
              pageBorderColor="transparent"
              activePageBorderColor="#6271EB"
              paddingX={10}
              paddingY={10}
            />
            <ZoomButtons store={store} />
            <PagesTimeline store={store} />
          </WorkspaceWrap>
        </PolotnoContainer>
      </OverlaysProvider>
    </div>
  );
});

unstable_registerToolbarComponent(
  "group",
  observer(({ store }) => (
    <Navbar.Group align="left">
      <AIMagicTextTypesMenu store={store} />
    </Navbar.Group>
  ))
);

export default EditorApp;
