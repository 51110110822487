import { Button, Dialog, DialogBody, InputGroup } from "@blueprintjs/core";
import * as RadioGroup from '@radix-ui/react-radio-group';
import { Dispatch, SetStateAction, useMemo, useState } from "react";

import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
} from "@/components/ui/card"
import { Badge, BadgeButton } from "./ui/badge";
import { cn } from "@/lib/utils";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

import { LEGACY_TEMPLATES, LEGACY_TEMPLATES_CATEGORIES } from "../legacyTemplates";
import Fuse from "fuse.js";

type LegacyTemplate = (typeof LEGACY_TEMPLATES)[number];
type Template = LegacyTemplate["value"];
type Category = (typeof LEGACY_TEMPLATES_CATEGORIES)[number]["value"];

export const templateCategoriesMap = Object.fromEntries(
  LEGACY_TEMPLATES_CATEGORIES.map((e) => [e.value, e.label]),
);

// TODO: Fix typing, remove assertion
export const templateCardInfoMap =
  Object.fromEntries(
    LEGACY_TEMPLATES.map((e) => [e.value, e])
  ) as Record<Template, LegacyTemplate>

// export const [selectedTemplate, setSelectedTemplate] = createSignal<Template>(
//   "legacy_questions_answers",
// );

function TbCheck(props: { className?: string }) {
  return (
    <svg fill="none" stroke-width="2" xmlns="http://www.w3.org/2000/svg" className={cn("icon icon-tabler icon-tabler-check", props.className)} width="1em" height="1em" viewBox="0 0 24 24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" style={{ overflow: "visible" }}><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l5 5l10 -10"></path></svg>
  );
}

const categoryTemplates = Object.fromEntries(
  LEGACY_TEMPLATES_CATEGORIES.map(
    ({ value }) => [
      value,
      value ? LEGACY_TEMPLATES.filter(
        (template) => (template.categories as readonly string[]).includes(value)
      ) : LEGACY_TEMPLATES
    ]
  )
) as Record<Category, LegacyTemplate[]>;

const fuses = Object.fromEntries(
  LEGACY_TEMPLATES_CATEGORIES.map(
    (category) => [category.value, new Fuse(
      categoryTemplates[category.value],
      {
        keys: ["title", "value", "description", "details"],
      }
    )]
  )
) as Record<Category, Fuse<LegacyTemplate>>;

const TemplateSelector = ({ selectedTemplate, setSelectedTemplate }: { selectedTemplate: Template, setSelectedTemplate: Dispatch<SetStateAction<Template>> }) => {
  const [open, setOpen] = useState(false);

  const [filterCategory, setFilterCategory] = useState<Category>("");

  // const isMkp = useMatch(() => "/mkp");

  const selectedTemplateInfo = useMemo(() => templateCardInfoMap[selectedTemplate], [selectedTemplate]);

  // createEffect(() => {
  //   if (
  //     user() &&
  //     !subscriptionActive() &&
  //     !selectedTemplateInfo().enableForFreeTrial
  //   ) {
  //     // NOTE: There's a good change this will cause an infinite loop if the template set here is not enabled for free trial
  //     setSelectedTemplate("legacy_questions_answers");
  //   }
  // });

  const [search, setSearch] = useState("");
  const filteredCardInfos = useMemo(() => search ? fuses[filterCategory].search(search).map((e) => e.item) : categoryTemplates[filterCategory], [search, filterCategory]);

  return (
    <>
      <Button onClick={() => setOpen(true)} className="font-medium text-center">
        {selectedTemplate ? <>Choose Template: {selectedTemplateInfo.title}</> : "Choose Template"}
      </Button>
      <Dialog
        isOpen={open}
        title="Choose a template"
        className="w-[unset] [&_.bp5-icon-small-cross]:text-black"
        onClose={() => setOpen(false)}
      >
        {/* {LEGACY_TEMPLATES.map(({ value, title }) => {
          const selected = () => selectedTemplate === value;
          return (
            <label className="sr-only">
              <input
                type="radio"
                name={name}
                value={value}
                checked={selected()}
                onChange={(e) => setSelectedTemplate(e.target.value as Template)}
                required
              />
              {title}
            </label>
          );
        })} */}
        <DialogBody className="flex max-h-[80vh] flex-col gap-4 md:max-w-[80vw] xl:max-w-3xl">

          <div className="flex flex-row gap-4 justify-between flex-wrap">
            <RadioGroup.Root
              className="flex flex-row gap-2 flex-wrap"
              name="category"
              value={filterCategory}
              onValueChange={(value) => {
                setFilterCategory(value as Category);
              }}
            >
              {LEGACY_TEMPLATES_CATEGORIES.map(({ value, label }) => (
                <RadioGroup.Item value={value} key={value} asChild>
                  <BadgeButton variant={filterCategory === value ? "default" : "outline"}>{label}</BadgeButton>
                </RadioGroup.Item>
              ))}
            </RadioGroup.Root>

            <InputGroup
              // fill
              value={search}
              placeholder="Search..."
              leftIcon="search"
              onValueChange={(name) => {
                setSearch(name);
              }}
            />
          </div>

          <RadioGroup.Root asChild onValueChange={(value) => {
            setSelectedTemplate(value as Template)
            setOpen(false)
          }}>
            <form className="flex flex-row flex-wrap justify-center gap-4 overflow-y-auto">
              {filteredCardInfos.map(({
                title,
                description,
                details,
                value,
                categories,
              }) => {
                const disabled = () => false;
                // !(enableForFreeTrial || (user() && subscriptionActive()));
                const selected = () => selectedTemplate === value;
                const content = () => (
                  <>
                    <img
                      alt={title}
                      className="mx-auto aspect-[8.5/11] w-full object-contain"
                      src={`/static/images/templates/${value}.jpg`}
                    />
                    <div className="flex flex-1 flex-col justify-between gap-4 p-4">
                      <div className="flex flex-1 flex-col gap-4">
                        <CardTitle>
                          <label htmlFor={`radio-template-${value}`}>{title}</label>
                        </CardTitle>

                        <div className="flex flex-row flex-wrap gap-2">
                          {categories.map((category) => (
                            <Badge variant="outline" key={category}>
                              {templateCategoriesMap[category]}
                            </Badge>
                          ))}
                        </div>

                        <CardDescription>{description}</CardDescription>
                      </div>

                      {details && (
                        <details
                          className="justify-self-end text-sm"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <summary className="text-base">Details</summary>
                          {details}
                        </details>
                      )}
                    </div>
                  </>
                );

                return (
                  <Card
                    key={value}
                    className={cn("w-48 transform overflow-clip border-4 transition duration-150 cursor-pointer",
                      selected() ? "border-primary/100" : "border-primary/0",
                      disabled() ? "opacity-50" : "hover:scale-[1.03]",
                    )}
                  >
                    {selected() && <TbCheck className="absolute start-0 top-0 h-12 w-12 rounded-ee-full border-b-2 border-e-2 border-primary bg-primary-foreground pb-2 pe-2 text-primary" />}
                    <RadioGroup.Item value={value} disabled={disabled()} itemID={`radio-template-${value}`} asChild>
                      <CardContent className="h-full w-full p-0">
                        {disabled() ? (
                          <Tooltip>
                            <TooltipTrigger
                              className="flex h-full w-full cursor-default flex-col items-start justify-start"
                              onClick={(e) => {
                                if (disabled()) {
                                  e.stopPropagation();
                                }
                              }}
                            >
                              {content()}
                            </TooltipTrigger>
                            <TooltipContent>
                              This template is only available to subscribers.
                            </TooltipContent>
                          </Tooltip>
                        ) : content()}
                      </CardContent>
                    </RadioGroup.Item>
                  </Card>
                );
              })}
              <Card className="w-48">
                <div className="flex flex-1 flex-col justify-between gap-4 p-4">
                  <div className="flex flex-1 flex-col gap-4">
                    <CardTitle>
                      Can't find what you're looking for?{" "}
                      <a href="/">Show me more resources!</a>
                    </CardTitle>
                    <CardDescription>
                      If you have a specific template you would like us to add,
                      let us know! New templates are added every week. Check out our
                      newsletter for updates!
                    </CardDescription>
                  </div>
                </div>
              </Card>
            </form>
          </RadioGroup.Root>
        </DialogBody>
      </Dialog >
      {/* <div hidden={isLegacyTemplate()}>
        <div className="flex flex-col gap-4">
          <h3>Number of Questions</h3>
          {[
            ["True or False", "count_tf"],
            ["Multiple Choice", "count_mc"],
            ["Fill in the Blank", "count_fib"],
            ["Short Answer", "count_sa"],
            ["Essay", "count_essay"],
          ].map(([label, name]) => (
            <LabelledInput
              id={name}
              label={label}
              type={"number"}
              value={"5"}
            />
          ))}
        </div>
      </div> */}
    </>
  );
};

export default TemplateSelector;
